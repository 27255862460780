import React, {Component} from 'react';
import Input from "./form_elements/Input";
import {Redirect, withRouter, RouteComponentProps} from "react-router";
import notify from '../util/notfication';
import api from '../util/api';
import { withTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";


interface PasswordChangeFormProps {
    errors: {},
    redirect: boolean,
    email: string,
    token: string,
    old_password: string,
    new_password: string,
    confirm_password: string,
}

interface PasswordChangePathParams {

}

type PasswordChangeProps = RouteComponentProps<PasswordChangePathParams>;

class PasswordChangeForm extends Component<PasswordChangeProps & {t: Function}, PasswordChangeFormProps> {
    constructor(props) {
        super(props);
        const email = new URLSearchParams(props.location.search).get('email');
        const token = props.location.pathname.split('/').pop();

        this.state = {
            errors: {},
            redirect: false,
            email: email ? email : '',
            token: token ? token : '',
            old_password: '',
            new_password: '',
            confirm_password: '',
        };
    }

    componentDidMount() {
        api().get('get-csrf').then(response => {});
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let forgotPassURL = 'change-password';

        try {
            const response = await api().post(forgotPassURL, {
                email: this.state.email,
                token: this.state.token,
                old_password: this.state.old_password,
                new_password: this.state.new_password,
                confirm_password: this.state.confirm_password,
            });

            notify(response.data.message, 'success');

            this.setState({redirect: true});
        } catch (error: any) {
            let errors: { email: string, password: string } = {email: '', password: ''};

            if (error.response.status == 400) {
                notify(error.response.data.message, 'danger');
                this.setState({redirect: true});
                return;
            }

            if (error.response && error.response.data.errors) {
                for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                    errors[fieldName] = message.pop();
                }
            }
            this.setState({errors: errors});
        }
    }

    render() {
        const { t } = this.props;
        if (this.state.redirect) {
            return <Redirect to={"/"} />
        }
        return <div className="content-block content-block--centered">
            <h3>{t("reset_pass")}</h3>
            <div className="info info--form info--gray">
                {t("password_reset_message")}
            </div>
            <form className="change-password-form" onSubmit={this.onSubmit} >
                <Input type={"hidden"}
                       required
                       value={this.state.email}
                       name={"email"} />

                <Input type={"hidden"}
                       required
                       value={this.state.token}
                       name={"token"} />

                <Input type={"password"}
                       error={ this.state.errors['new_password'] }
                       placeholder={ t("new_password") }
                       value={this.state.new_password}
                       onChange={e => this.setState({new_password: e.target.value})}
                       name={"new_password"} />

                <Input type={"password"}
                       error={ this.state.errors['confirm_password'] }
                       placeholder={ t("confirm_password") }
                       value={this.state.confirm_password}
                       onChange={e => this.setState({confirm_password: e.target.value})}
                       name={"confirm_password"} />

                <div className="dialog-links">
                    <button type="submit" className="btn btn--primary btn--big" >{ t("reset_pass_button") }</button>
                </div>
            </form>

        </div>
    }
}

export default withTranslation()(withRouter(PasswordChangeForm));
