import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import i18n from "../i18n";
import { t } from 'i18next';

interface AusstellungCardProps {
    date: string;
    offen?: number;
    anmeldungenCount?: number;
    ausstellung_id: number;
    name: string;
    ort: string;
    showUserData: boolean | null;
    archived: boolean;
    onlineanmeldung_moeglich: number;
    registration_available: boolean;
}

const AusstellungCardUserData = (props) => {
    let offeneClass: string = 'event-information-user-data__content-offen-value';
    if (props.offen !== undefined) {
        offeneClass = 'event-information-user-data__content-offen-value price-available';
    } else {
        props.offen = 0;
    }

    let anmeldungenCountClass: string = 'event-information-user-data__content-anmeldungen-count';
    if (props.anmeldungenCount !== undefined) {
        anmeldungenCountClass = 'event-information-user-data__content-anmeldungen-count anmeldungen-available';
    } else {
        props.anmeldungenCount = 0;
    }

    const { t } = useTranslation();

    if (props.showUserData) {
        return <div className="event-information-user-data">
            <div className="event-information-user-data__content">
                <div className="event-information-user-data__content-anmeldungen">
                    <span className="event-information-user-data__content-label">{ t("your_registrations") }</span>
                    <a className="card-link" href={`/anmeldung-list?filter=${props.ausstellungName}`}>
                        <span className={anmeldungenCountClass}>{props.anmeldungenCount}</span>
                    </a>
                </div>
                <div className="event-information-user-data__content-offen">
                    <span className="event-information-user-data__content-label">{ t("updaid_amount") }: </span>

                    <a className="card-link" href={'/user/zahlungen'}>
                        <span className={offeneClass}>€ {props.offen}</span>
                    </a>
                </div>
            </div>
        </div>
    }

    return (null);
}

const AnmeldenButton = (props) => {
    const { t } = useTranslation();
    return <a className="btn btn--primary btn--add" href={`/ausstellung/${props.ausstellungID}`}>
        <i className="ico icon-cancel-circle text-uppercase"></i> {t("register")}
    </a>
};

const StatisticsButton = (props) => {
    const { t } = useTranslation();
    return <a className="btn btn--border btn--stats"
              target="_blank"
              href={`https://verwaltung.oekv.at/anmeldestatistik/index.php?ausstellung_id=${props.ausstellungID}`}>
        <i className="ico icon-stats-dots"></i> {t("statistics")}
    </a>;
};

const AusstellungCard: React.FC<AusstellungCardProps> = (props) => {
    const weekdays = [
        "sunday",
        "monday",
        "tuesday",
        "wedensday",
        "thursday",
        "friday",
        "saturday"
    ];

    const date = new Date(props.date);
    const { t } = useTranslation();

    return (
        <div className="col-lg-4 event-information-wrapper ">
            <div className="event-information clearfix">
                <article className="card fl-left">
                    <section className="date">
                        <time dateTime="23th feb">
                            <span className="date__day">{date.getDate()}</span>
                            <span className="date__month">{date.toLocaleDateString(i18n.language, {month: "short"})}</span>
                        </time>
                    </section>
                    <section className="card-cont">
                        <div className="card__event-info">
                            <h3>{props.name}</h3>
                            <div className="card__event-info__details">
                                <div className="time">
                                    <i className="ico icon-date"></i>{t(weekdays[date.getDay()])}, {date.getDate()}. {date.toLocaleDateString(i18n.language, {month: "long"})} {date.getFullYear()}
                                </div>
                                <div className="place">
                                    <i className="ico icon-place"></i>{props.ort}
                                </div>
                            </div>
                        </div>

                        <div className="card-buttons">
                            {props.showUserData ? <StatisticsButton ausstellungID={props.ausstellung_id}/> : ''}
                            {props.showUserData && (props.archived !== true && props.registration_available == true) ?
                                <AnmeldenButton ausstellungID={props.ausstellung_id}/> : ''}
                        </div>
                    </section>

                </article>
            </div>

            <AusstellungCardUserData
                anmeldungenCount={props.anmeldungenCount}
                offen={props.offen}
                showUserData={props.showUserData}
                ausstellungName={props.name}
            />


        </div>
    );
};

export default AusstellungCard;
