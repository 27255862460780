import { useTranslation } from 'react-i18next';

const PaymentEntry = (props) => {
    const { t } = useTranslation();

    return <div className="payment-entry">
        <div className="row align-items-center justify-content-between">
            <div className="col-lg-3 align-items-center">
                <div className="payment-entry__basic-info">
                    <div className="payment-entry__date">{props.data.beginndatum}</div>
                    <span className="payment-entry__name">{props.data.name}</span>
                </div>
            </div>
            <div className="col-lg-3  align-items-center">
                <div className="payment-entry__anmeldung-count-wrapper justify-content-end d-flex align-items-center">
                    <a href={`/anmeldung-list?filter=${props.data.name}`} className="payment-entry__label payment-entry__label--link" >{ t("registrations") }</a>
                    <span className="payment-entry__anmeldung-count text-center">{props.data.anmeldung_count}</span>
                </div>
            </div>
            <div className="col-lg-3 align-items-center">
                <div className="payment-entry__price-details">
                    <span className="payment-entry__label">{ t("payment_costs") }</span>
                    <span className="payment-entry__price">€ {props.data.price}</span>
                </div>
                <div className="payment-entry__price-details">
                    <span className="payment-entry__label">{ t("payment_payed") }</span>
                        <span className="payment-entry__price">€ {props.data.payed}</span>
                </div>
            </div>
            <div className="col-lg-3 d-flex justify-content-end align-items-center">
                <div className="payment-price">€ {props.data.sum}</div>
            </div>
        </div>
    </div>
}

export default PaymentEntry;
