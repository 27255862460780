import IconTooltip from "./IconTooltip";
import React from "react";
import FormElementError from "./FormElementError";

const Select = (props) => {
    if (props.rawMarkup) {
        return <select
            className="form-control form-select"
            onChange={props.onChange}
            value={props.value ? props.value : ""}
            name={props.name}
        >
            {props.options.map((item, idx) => {
                return (
                    <option key={idx} value={item.value}>
                        {" "}
                        {item.label}{" "}
                    </option>
                );
            })}
        </select>;
    }

    return (
        <div
            className={
                props.error != null && props.error != ""
                    ? "form-element-wrapper has-error"
                    : "form-element-wrapper"
            }
        >
            <label className={"required"}>{props.label}</label>
            <div className="input-group mb-3">
                <select
                    className="form-control form-select"
                    onChange={props.onChange}
                    value={props.value ? props.value : ""}
                    name={props.name}
                >
                    <option disabled></option>
                    {props.options.map((item, idx) => {
                        return (
                            <option key={idx} value={item.value}>
                                {" "}
                                {item.label}{" "}
                            </option>
                        );
                    })}
                </select>
                {props.tooltip ? (
                    <IconTooltip tooltip={props.tooltip} />
                ) : null}
            </div>
            {props.error ? <FormElementError message={props.error} /> : ""}
        </div>
    );
};

export default Select;
