 import axios from "axios";
 import { logOut } from "../util/auth";
 import { API_URL } from "../constants"

axios.defaults.baseURL = API_URL;

// if (localStorage.getItem('token')) {
//     axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
// }

const api = () => {
    const api = axios.create({
        baseURL: `${API_URL}/api`,
        withCredentials: true
    })

    api.interceptors.response.use(response => response, error => {

        if (error.response.status === 401
            && !error.request.responseURL.includes('api/login')
            && !error.request.responseURL.includes('api/logout')) {
            logOut();

            return Promise.reject(error)
        }

        return Promise.reject(error);
    })

    return api
}

export default api;
