import React, {Component, ReactElement} from 'react';
import FormElementError from "./FormElementError";

interface InputProps {
    type: string,
    placeholder?: string,
    error?: string | null,
    classes?: string | null,
    disabled?: boolean,
    icon?: ReactElement,
    iconOrientation?: string,
    name: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value?: string,
    required?: boolean
    label?: string
};

interface InputState { };

class Input extends Component<InputProps, InputState> {
    placeholder: string = 'Please enter value';
    iconOrientation: string = 'left';
    error: string | null;

    constructor(props) {
        super(props);

        this.placeholder =  props.placeholder;
        this.error = props.error;
        this.iconOrientation = props.iconOrientation;
    }

    render() {

        return <div className={this.props.error != null && this.props.error != '' ? "form-element-wrapper has-error" : "form-element-wrapper"}>
            { this.props.label ?  <label className={this.props.required ? 'required' : ''} htmlFor={this.props.name}>{this.props.label}</label> : '' }

            <div className="input-group">
                {this.props.icon && this.props.iconOrientation === 'left' ? this.props.icon : ''}

                <input type={this.props.type}
                       value={this.props.value}
                       onChange={this.props.onChange}
                       className={this.props.classes ? this.props.classes : "form-control" }
                       disabled={this.props.disabled ? this.props.disabled : false}
                       placeholder={this.props.placeholder}
                       name={this.props.name}></input>


                {this.props.icon && this.iconOrientation === 'right' ? this.props.icon : ''}
            </div>

            { this.props.error ? <FormElementError message={this.props.error} /> : '' }
        </div>;
    }
}

export default Input;
