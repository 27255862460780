import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const  Static = () => {
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="row">
                <div className="content-block content-block--static  col-lg-6">
                    <h2>
                        <Trans i18nKey={"intro_heading"}>
                        &shy;
                        </Trans>
                     </h2>
                    { t("intro_body") }
                </div>
                <div className="content-block__image col-lg-6 d-lg-flex">
                    <img src="../images/img1.png" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Static;
