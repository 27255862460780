import Cookies from 'js-cookie';
import cookie from 'cookie';

export const isLoggedIn = (reqCookies = null) => {
    // if we don't have request cookies, get the cookie from client
    if (! reqCookies) {
        return !! Cookies.get('hundeausstellung_is_user_logged_in')
    }

    // otherwise get cookie from server
    return !! cookie.parse(reqCookies).ticket_management_is_user_logged_in
}

export const logIn = () => {
    Cookies.set('hundeausstellung_is_user_logged_in', true, {expires: 86400, sameSite: 'lax'})

    window.location.href = '/'
}

export const logOut = () => {
    if (typeof window !== 'undefined') {
        Cookies.remove('hundeausstellung_is_user_logged_in', {expires: 86400, sameSite: 'lax'})
        Cookies.remove('hundeausstellung_impersonate', {expires: 86400, sameSite: 'lax'})

        window.location.href = '/'
    }
}

export const impersonate = () => {
    if (typeof window !== 'undefined') {
        Cookies.set('hundeausstellung_impersonate', true, {expires: 86400, sameSite: 'lax'})

        window.location.href = '/'
    }
}

export const leaveImpersonate = () => {
    if (typeof window !== 'undefined') {
        Cookies.remove('hundeausstellung_impersonate', {expires: 86400, sameSite: 'lax'})

        window.location.href = '/'
    }
}
