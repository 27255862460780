import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ThankYouPage = (props) => {
    const { t } = useTranslation();
    return <div className="container thank-you-wrapper text-center">
        <div className="row">
            <div className="col-12 content-block">
                <h2>{t("thank_you")}</h2>
                <p>{t('thank_you_message')}</p>
                <a href="/" className="btn btn--primary btn--add to-overview">{t('to_overview')}</a>
            </div>
        </div>
    </div>
}


export default ThankYouPage;
