const AccessDenied = (props) => {
    return <div className="d-flex justify-content-center align-items-center flex-column" style={{minHeight: "300px"}}>
        <h1 className="display-1 font-weight-bold">403</h1>
        <h3 className="display-6 font-weight-bold">Access denied</h3>
    </div>
}


export default AccessDenied;


