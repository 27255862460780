import { store } from 'react-notifications-component';

const notify = (message, type) => {
    store.addNotification({
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000
        }
    });
}

export default notify;
