import {default as ReactCheckbox} from "react-custom-checkbox";
import FormElementError from "./FormElementError";
import React from "react";

const Checkbox = (props) => {
    return <div className={props.error != null && props.error != '' ? "form-element-wrapper has-error" : "form-element-wrapper"}>
        <ReactCheckbox className={"input__checkbox"}
                  borderRadius={0}
                  size={30}
                  checked={props.checked}
                  borderWidth={3}
                  icon={<div><i className="icon-checkmark"></i></div>}
                  borderColor="#7F7F7F"
                  style={{backgroundColor: "#DDDDDD"}}
                  label={props.label} // "Ich erkläre mich einverstanden die gültigen Tierschutzbestimmungen einzuhalten."
                  name={props.name} // "accept_rules"       "field-label required"
                  labelClassName={props.labelClassName}
                  onChange={ value => {
                      props.onChange(value);
                  }}/>
        { props.error ? <FormElementError message={props.error} /> : '' }
    </div>;
}

export default Checkbox;


