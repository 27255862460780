import React from 'react';
import Static from "./Static";
import AusstellungList from "./AusstellungList";

const Homepage = () => {
    return (
        <div className="container-fluid page">
            <div className="row">
                <div className="col-lg-12">
                    <Static/>
                    <AusstellungList/>
                </div>
            </div>
        </div>
    );
};

export default Homepage;