import React from 'react';

const ScrollUpButton = () => {
    return <div className={"scroll-up-button"} onClick={() => {
        window.scrollTo(0, 0);
    }}>
        <i className="icon-arrow-left2"></i>
    </div>
}

const  Footer = () => {
    return (
        <div className="container-fluid footer">
            <div className="row">
                <div className="col-lg-12">
                    <div className="container footer-content-container">
                        <div className="row position-relative footer-row">
                            <ScrollUpButton />
                            <div className="col-md-4 footer-info-wrapper">
                                <img src="/images/footer-logo.png" alt=""/>
                                <div className="footer-info">
                                    <h2>ÖSTERREICHISCHER KYNOLOGENVERBAND</h2>
                                    <p>Eingetragener Verein in Österreich</p>
                                </div>
                            </div>
                            <div className="col-md-4 footer-addesses">
                                <p className="footer-addess">Siegfried Marcus-Straße 7 Austria</p>
                                <p className="footer-addess">2362 Biedermannsdorf</p>
                                <p className="footer-addess">Austria</p>
                            </div>
                            <div className="col-md-4 footer-contacts">
                                <p className="footer-contact">Telefon: +43 2236/710 667</p>
                                <p className="footer-contact">Fax: +43 2236/710 667-30</p>
                                <p className="footer-contact">E-Mail: office@oekv.at</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Footer;
