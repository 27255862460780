
import api from "../util/api";
import { RouteComponentProps, withRouter} from "react-router";
import axios from "axios";
import React, {Component, SyntheticEvent, useState, useCallback, useEffect} from "react";
import DataTable from 'react-data-table-component';
import MeineAnmeldungHeader from './MeineAnmeldungHeader';
import ReactTable, { useTable, useSortBy, useGlobalFilter, usePagination, Column, Row, IdType } from 'react-table';
import Input from "./form_elements/Input";
import Pagination from './Pagination';
import { matchSorter } from "match-sorter";
import { useTranslation } from 'react-i18next';






type MeineAnmeldungListProps = {

}

type MeineAnmeldungListState = {

}



const SortedIndicator = (props) => {
    const ascendingIndicatorClasses = props.sort == 'asc' ? "sort-indicator__carret-top sort-indicator__carret-top--active" : 'sort-indicator__carret-top';
    const descendingIndicatorClasses = props.sort == 'desc' ? "sort-indicator__carret-bottom sort-indicator__carret-bottom--active" : 'sort-indicator__carret-bottom';

    return <div className="sort-indicator">
        <div className={ascendingIndicatorClasses}><i className="icon icon-carret"></i></div>
        <div className={descendingIndicatorClasses}><i className="icon icon-carret"></i></div>
    </div>
}


const Page = (props: {pageNumber: number, gotoPage: Function, pageIndex: number}) => {
    return <li className="pager__page">
        {
            props.pageIndex == (props.pageNumber - 1) ?
            <a href="#" className="active" onClick={e => {
                e.preventDefault();
                props.gotoPage(props.pageNumber - 1);
            }}>{props.pageNumber}</a> :

            <a href="#" onClick={e => {
                e.preventDefault();
                props.gotoPage(props.pageNumber - 1);
            }}>{props.pageNumber}</a>}

    </li>
}

type PagerProps = {
    gotoPage: Function,
    pageCount: number,
    pageIndex: number,
    canPreviousPage: boolean,
    canNextPage: boolean,
    nextPage: Function,
    previousPage: Function
};

const Pager = (props: PagerProps ) => {
    let pages: {}[] = [];

    for (let pageNumber = 1; pageNumber <= props.pageCount; pageNumber++) {
        pages.push(<Page key={pageNumber} pageNumber={pageNumber} gotoPage={props.gotoPage} pageIndex={props.pageIndex} />)
    }

    return <ul className="pager">
        {props.canPreviousPage ? <li className="pager__page">
            <a href="#" onClick={ e => { e.preventDefault(); props.previousPage() } }>{"<"}</a>
        </li> : <></>}

        {pages}

        <li className="pager__page"></li>
        {props.canNextPage ? <li className="pager__page">
            <a href="#" onClick={ e => { e.preventDefault(); props.nextPage() } }>{">"}</a>
        </li> : <></>}

    </ul>
}


function MeineAnmeldungList (props) {

      const filterGetValue = React.useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('filter');
      }, [])

      const [ filter, setFilter ] = useState(filterGetValue ? filterGetValue : '');
      const { t } = useTranslation();

      const openModal = props.openModal;

      const columns = React.useMemo(
        () => [
            {
                Header: t("freischaltung"),
                accessor: 'freischaltung',
                sortable: true,
                Cell: (props) => {
                    const { value, cell } = props;

                    if (value == 0) {
                        return <span className="anmeldung-table__cell-freischaltung anmeldung-table__cell-freischaltung--wartend">{ t("prufen") }</span>;
                    }
                    if (value == 1) {
                        return <span className="anmeldung-table__cell-freischaltung anmeldung-table__cell-freischaltung--freigeschalten">OK</span>;
                    }
                    if (value == 2) {
                        return <span className="anmeldung-table__cell-freischaltung anmeldung-table__cell-freischaltung--gesperrt">{ t("gesperrt") }</span>;
                    }

                    return <></>
                }
            },
            {
                Header: t("ausstellung"),
                accessor: 'ausstellung',
                sortable: true,
                class: 'anmeldung-table__colum-ausstellung',
            },
            {
                Header: t("date"),
                accessor: 'begindatum',
                sortable: true,
                class: 'anmeldung-table__colum--centered',
            },
            {
                Header: t("hundename"),
                accessor: 'hundename',
                sortable: true,
                class: 'anmeldung-table__colum-hundename',

            },
            {
                Header: 'ZB-Nr.',
                accessor: 'zuchtbuchnummer',
                sortable: true,
                class: 'anmeldung-table__colum--centered'
            },
            {
                Header: t("geschlecht"),
                accessor: 'geschlecht',
                sortable: true,
                class: 'anmeldung-table__colum--centered',
                Cell: (props) => {
                    const { value, cell } = props;

                    if (value == 'H') {
                        return <span className="anmeldung-table__cell-geschlecht anmeldung-table__cell-geschlecht--h">{value}</span>;
                    } else {
                        return <span className="anmeldung-table__cell-geschlecht anmeldung-table__cell-geschlecht--r">{value}</span>;
                    }
                }
            },
            {
                Header: t("klasse"),
                accessor: 'klasse',
                sortable: true,
                class: 'anmeldung-table__colum--centered'
            },
            {
                Header: 'PK',
                accessor: 'paarklasse',
                class: 'anmeldung-table__colum--centered anmeldung-table__colum-checkbox',
                Cell: (props) => {
                    const { value, cell } = props;

                    if (value == '1') {
                        return <span className="anmeldung-table__cell-check">
                                <i className="icon icon-checkmark"></i>
                        </span>;
                    }

                    return <></>
                }
            },
            {
                Header: 'ZG',
                accessor: 'zuchtgruppe',
                class: 'anmeldung-table__colum--centered anmeldung-table__colum-checkbox',
                Cell: (props) => {
                    const { value, cell } = props;

                    if (value == '1') {
                        return <span className="anmeldung-table__cell-check">
                                <i className="icon icon-checkmark"></i>
                        </span>;
                    }

                    return <></>
                }
            },
            {
                Header: 'JH',
                accessor: 'junior',
                class: 'anmeldung-table__colum--centered anmeldung-table__colum-checkbox',
                Cell: (props) => {
                    const { value, cell } = props;

                    if (value == '1') {
                        return <span className="anmeldung-table__cell-check">
                                <i className="icon icon-checkmark"></i>
                        </span>;
                    }

                    return <></>
                }
            },
            {
                Header: '',
                accessor: 'ids',
                disableSortBy: true,
                Cell: (props) => {
                    const { value, cell } = props;

                    const status = props.row.cells[0].value;

                    if (value.ausstellung && value.anmeldung && status != 1) {
                        return <div className="anmeldung-table__buttons">
                            <a href={`/ausstellung/${value.ausstellung}/anmeldung/${value.anmeldung}/edit`} className="anmeldung-table__button anmeldung-table__button--edit">
                                {/* <i className="icon icon-edit"></i> */}
                            </a>
                            <a href="#"
                                data-ausstellung={value.ausstellung_name}
                                data-hundename={value.hundename}
                                data-anmeldung-id={value.anmeldung}
                                className="anmeldung-table__button anmeldung-table__button--delete"
                                onClick={openModal}>
                                {/* <i className="icon icon-delete"></i> */}
                            </a>
                        </div>
                    }

                    return <></>
                }
            },
        ],
        []
      )

      const filters = [
          'ausstellung', 'hundename', 'begindatum', 'zuchtbuchnummer', 'klasse'
      ];

      const data = props.data;

      const globalFilterFunction = useCallback(
        (rows: Row[], ids: IdType[], query: string) => {
          return matchSorter(rows, query, {
            keys: filters.map((columnName) => `values.${columnName}`)
          });
        },
        [filters]
      );

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageCount,
        gotoPage,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize },
        prepareRow,
        setGlobalFilter
      } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 5, globalFilter: filterGetValue}, globalFilter: globalFilterFunction}, useGlobalFilter, useSortBy, usePagination)


      useEffect(() => {
        setGlobalFilter(filter);
      }, [filter, setGlobalFilter]);

      return (
        <div className="container page">
            <div className="row mb-3">
                <div className="col-12">
                    <MeineAnmeldungHeader />
                </div>
            </div>
            <div className="row">
                 <div className="col-12">
                     <div className="content-block">
                        <div className="row justify-content-end">
                            <div className="anmeldung-table__filter d-flex align-items-center">
                                <label className="anmeldung-table__filter-search-label">{ t("suche") }</label>
                                <Input type={"text"}
                                    value={filter}
                                    classes={"form-control anmeldung-table__filter-input"}
                                    iconOrientation={"left"}
                                    onChange={e => setFilter(e.target.value)}
                                    name={"filter"} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {/* <ReactTable PaginationComponent={Pagination} data={data} columns={columns} /> */}
                                <table {...getTableProps()} className="anmeldung-table">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())} >
                                                <div className={"anmeldung-table__heading-wrapper d-flex " + column.class}>
                                                    <div>
                                                        {column.render('Header')}
                                                    </div>
                                                    {
                                                        !column.disableSortBy ? (column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <SortedIndicator sort={"desc"} />
                                                            : <SortedIndicator sort={"asc"} />
                                                        : <SortedIndicator />) : ''
                                                    }
                                                </div>
                                            </th>
                                            ))}
                                        </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                <td {...cell.getCellProps({className: cell.column.class})} >
                                                    {cell.render('Cell')}
                                                </td>
                                                )
                                            })}
                                            </tr>
                                        )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 justify-content-end d-flex">
                                <Pager pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} canPreviousPage={canPreviousPage} canNextPage={canNextPage} nextPage={nextPage} previousPage={previousPage}/>
                            </div>
                        </div>
                     </div>
                     <div className="anmeldung-overview__buttons-wrapper">
                         <a href={`/`} className="btn btn--biggest btn--primary btn--add"><i className="ico icon-cancel-circle"></i>{ t("meine_anmeldungen_button_1") }</a>
                         <a href={`/user/zahlungen`} className="btn btn--biggest btn--primary btn--add"><i className="ico icon-cancel-circle"></i>{ t("meine_anmeldungen_button_2") }</a>
                     </div>
                 </div>
            </div>
        </div>
      )
}







export default MeineAnmeldungList;










const columns = [
    {
        name: 'STATUS',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'AUSSTELLUNG',
        selector: row => row.ausstellung,
        sortable: true,
    },
    {
        name: 'DATUM',
        selector: row => row.begindatum,
        sortable: true,
    },
    {
        name: 'HUNDENAME',
        selector: row => row.hundename,
        sortable: true,
    },
    {
        name: 'ZB-Nr.',
        selector: row => row.zuchtbuchnummer,
        sortable: true,
    },
    {
        name: 'GESCHLECHT',
        selector: row => row.geschlecht,
        sortable: true,
    },
    {
        name: 'Klasse',
        selector: row => row.klasse,
        sortable: true,
    },
    {
        name: 'PK',
        selector: row => row.paarklasse,
    },
    {
        name: 'ZG',
        selector: row => row.zuchtgruppe,
    },
    {
        name: 'JH',
        selector: row => row.junior,
    },
    {
        name: '',
        selector: row => row.junior,
    },
];
