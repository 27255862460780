
import { useContext } from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadOverlay = (props) => {
    return <div className={"load-overlay"}>
        <LoadingSpinner  />
    </div>
}

export default LoadOverlay;
