const NotFoundRoute = (props) => {
    return <div className="d-flex justify-content-center align-items-center flex-column" style={{minHeight: "300px"}}>
        <h1 className="display-1 font-weight-bold">404</h1>
        <h3 className="display-6 font-weight-bold">Not found</h3>
    </div>
}


export default NotFoundRoute;


