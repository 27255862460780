import React, { Component } from 'react';
import AusstellungCard from "./AusstellungCard";
import api from "../util/api";
import { isLoggedIn } from "../util/auth";
import { withTranslation, useTranslation } from 'react-i18next';
import LoadingSpinner from "./LoadingSpinner";

type Ausstellung = {
    ausstellung_id: number;
    anmeldungenCount?: number | undefined;
    offen?: number | undefined;
    name: string;
    beginndatum: string;
    ort: string;
}


type AusstellungProps = {
    ausstellung_list: Ausstellung[]
    sortyBy: string;
    order: string;
    loading: boolean;
    hideLoadMore: boolean;
    archived: boolean
    isLoggedIn: boolean | null
}

const LoadMoreButton = (props) => {
    const { t } = useTranslation();

    if (props.hideLoadMore) {
        return (null)
    }

    return <div className="row">
        <div className="col-lg-12 text-center">
            <button className="load-more" onClick={props.onClick}>
                <span className={"load-more__text"}>{t("load_more")}</span>
                <i className="load-more__icon icon-load-more">
                    <span></span>
                    <span></span>
                    <span></span>
                </i>

            </button>
        </div>
    </div>
}

const AusstellungItems = (props) => {
    return <div>
            <div className="row">
                {props.ausstellung_list === undefined ? '' : props.ausstellung_list.map(item => {
                    return <AusstellungCard
                        key={item.ausstellung_id}
                        ausstellung_id={item.ausstellung_id}
                        name={item.name}
                        ort={item.ort}
                        archived={item.archived}
                        onlineanmeldung_moeglich={item.onlineanmeldung_moeglich}
                        registration_available={item.registration_available}
                        showUserData={props.isLoggedIn}
                        offen={item.offen}
                        date={item.beginndatum}
                        anmeldungenCount={item.anmeldungenCount}
                    />
                })}
            </div>
            <LoadMoreButton onClick={props.loadMore} hideLoadMore={props.hideLoadMore} />
        </div>
        ;
}

class AusstellungList extends Component<{t: Function}, AusstellungProps> {
    fetchURL = 'ausstellung/list';

    constructor(props) {
        super(props);
        this.state = {
            sortyBy: 'datum',
            loading: false,
            hideLoadMore: false,
            order: 'asc',
            archived: false,
            isLoggedIn: isLoggedIn(),
            ausstellung_list: [],
        };
    }

    loadMore = () => {
        this.setState({
            loading: true,
        }, () => {
            let count = this.state.ausstellung_list.length;
            api().get(this.fetchURL + `?sortBy=${this.state.sortyBy}&order=${this.state.order}&archived=${this.state.archived}&itemsCount=${count}`).then(response => {
                let ausstellung_list = [ ...this.state.ausstellung_list, ...response.data.ausstellungen];
                let hideLoadMore = response.data.totalCount == ausstellung_list.length;
                this.setState({ ausstellung_list: ausstellung_list, hideLoadMore: hideLoadMore, loading: false} )
            });

        });
    }

    sortData = () => {
        let ids: number[] = [];
        this.state.ausstellung_list.map(item => {
            ids.push(item.ausstellung_id)
        })

        api().post('ausstellung/list/sort' + `?sortBy=${this.state.sortyBy}&order=${this.state.order}`, {ids: ids}).then(response => {
            this.setState({ ausstellung_list: response.data } )
        });
    };

    fetchData = () => {
        this.setState({
            loading: true,
        }, () => {
            let count = this.state.ausstellung_list.length;
            api().get(this.fetchURL + `?sortBy=${this.state.sortyBy}&order=${this.state.order}&archived=${this.state.archived}&itemsCount=${count}`).then(response => {
                let hideLoadMore = response.data.totalCount == response.data.ausstellungen.length;
                this.setState({ ausstellung_list: response.data.ausstellungen, hideLoadMore: hideLoadMore, loading: false} )
            });
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { t } = this.props;
        const sortByValues = {
            datum: t("date"),
            name: 'Name'
        };
        const orderValues = {
            desc: t('descending'),
            asc: t('ascending')
        };

        return <div className="container ausstellung-list">
            <div className="row">
                <div className="col-12">
                    <div className="ausstellung-list__header">
                        <div className="ausstellung-list__title text-uppercase">{t("dog_shows")}</div>
                        {/*<AusstellungListFilters/>*/}

                        <div className="ausstellung-list__filters">
                            <div className="ausstellung-list__filter-group">
                                <span className="filter-label text-uppercase">{t("sort_by")}</span>
                                <div className="filter-select">
                                    <div className="btn-group input-group">
                                        <div data-bs-toggle="dropdown">
                                            <button type="button" className="dropdown-toggle-selection filter-btn">{sortByValues[this.state.sortyBy]}</button>
                                            <button type="button" className="dropdown-toggle-split filter-btn" aria-expanded="false">
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                        </div>
                                        <ul className="dropdown-menu" data-popper-placement="bottom-end">
                                            <button className={this.state.sortyBy === 'datum' ? "dropdown-item selected" : "dropdown-item"}
                                               onClick={e => {
                                                   this.setState({ sortyBy: 'datum'}, () => {
                                                       this.sortData();
                                                   });
                                               }}
                                               >{t("date")}</button>
                                            <button className={this.state.sortyBy === 'name' ? "dropdown-item selected" : "dropdown-item"}
                                               onClick={e => {
                                                   this.setState({ sortyBy: 'name'}, () => {
                                                       this.sortData();
                                                   });
                                               }}
                                               >Name</button>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="ausstellung-list__filter-group">
                                <span className="filter-label"> {t("sort_order")} </span>
                                <div className="filter-select">
                                    <div className="btn-group">
                                        <div data-bs-toggle="dropdown">
                                            <button type="button" className="dropdown-toggle-selection filter-btn">{orderValues[this.state.order]}</button>
                                            <button type="button" className="dropdown-toggle-split filter-btn" aria-expanded="false">
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                        </div>
                                        <div className="dropdown-menu" data-bs-placement="top">
                                            <button className={this.state.order === 'asc' ? "dropdown-item selected" : "dropdown-item"}
                                               onClick={e => {
                                                   this.setState({ order: 'asc'}, () => {
                                                       this.sortData();
                                                   });
                                               } }
                                               >{t("ascending")}</button>
                                            <button className={this.state.order === 'desc' ? "dropdown-item selected" : "dropdown-item"}
                                               onClick={e => {
                                                   this.setState({ order: 'desc'}, () => {
                                                       this.sortData();
                                                   });
                                               } }
                                               >{t("descending")}</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="ausstellung-list__filter-group">
                                <span className="filter-label"> Filter </span>
                                <div className="filter-switch">
                                    <button
                                       className={!this.state.archived ? "filter-switch__item active filter-btn" : "filter-switch__item filter-btn"}
                                       onClick={e => {
                                           this.setState({ archived: false, ausstellung_list: []}, () => {
                                               this.fetchData();
                                           });
                                       } }>{t("upcoming")}</button>
                                    <button
                                       className={this.state.archived ? "filter-switch__item active filter-btn" : "filter-switch__item filter-btn"}
                                       onClick={e => {
                                           this.setState({ archived: true, ausstellung_list: []},() => {
                                               this.fetchData();
                                           });
                                       } }>{t("archive")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading ? <LoadingSpinner classes={"load-spinner load-spinner__list"}/> : <AusstellungItems
                            ausstellung_list={this.state.ausstellung_list}
                            isLoggedIn={this.state.isLoggedIn}
                            loadMore={this.loadMore}
                            hideLoadMore={this.state.hideLoadMore}/>
                    }
                </div>
            </div>
        </div>
    };
}

export default withTranslation()(AusstellungList);
