import React, {Component, SyntheticEvent} from "react";
import Input from "./form_elements/Input";
import IconTooltip from "./form_elements/IconTooltip";
import IconPassword from "./form_elements/IconPassword";
import Select from "./form_elements/Select";
import api from "../util/api";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import LoadOverlay from "../components/LoadOverlay";
import notify from '../util/notfication';


interface RegisterUserFormProps {
    name: string,
    vorname: string,
    landOptionsReceived: boolean,
    loading: boolean,
    email: string,
    strasse: string,
    plz: string,
    land_id: number | null,
    ort: string,
    password: string,
    password_repeat: string,
    errors: {}
}

interface UserRegisterResponse {

}

class RegisterUserForm extends Component<{  t: Function }, RegisterUserFormProps> {
    landOptions = [];
    landEnOptions = [];

    constructor(props) {
        super(props);
        this.state = {
            landOptionsReceived: false,
            loading: false,
            errors: {},
            name: '',
            vorname: '',
            land_id: null,
            plz: '',
            ort: '',
            password: '',
            password_repeat: '',
            email: '',
            strasse: '',
        };

    }

    async componentDidMount()  {

        const response = await api().get('user/register/form-data');
        this.landOptions = response.data.land;
        this.landEnOptions = response.data.land_en;
        this.setState({landOptionsReceived: true});
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { t } = this.props;
        this.setState({ errors: {}, loading: true });
        let URL = 'user/register';

        try {
            const response = await api().post<UserRegisterResponse>(URL, {
                name: this.state.name,
                strasse: this.state.strasse,
                plz: this.state.plz,
                ort: this.state.ort,
                email: this.state.email,
                land_id: this.state.land_id,
                password: this.state.password,
                password_repeat: this.state.password_repeat,
            });

            this.setState({ loading: false });


            // change to cookies only
            // localStorage.setItem('token', response.data.token );

            notify(t("register_success"), 'success');
            setTimeout(() => {
                window.location.href = '/';
            }, 2500)

        } catch (error: any) {
            let errors: {email: string, password: string} = {email: '', password: ''};
            if (error.response.data.errors) {
                for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                    errors[fieldName] = message.pop();
                }
            }
            this.setState({errors: errors, loading: false});
        }


    };

    render() {
        const { t } = this.props;

        return <div className="container">

            <div className="row">
                <div className="col-lg-12">
                    <div className="content-block content-block--centered">
                        <h3 className={"mb-5"}>{ t("user_resgistration") }</h3>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           error={this.state.errors['name']}
                                           label={"NAME"}
                                           required
                                           value={this.state.name}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_name_tooltip")} />}
                                           onChange={e => this.setState({name: e.target.value})}
                                           name={"name"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['strasse']}
                                           label={ t("strasse") }
                                           value={this.state.strasse}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_strasse_tooltip")} />}
                                           onChange={e => this.setState({strasse: e.target.value})}
                                           name={"name"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"email"}
                                           required
                                           error={this.state.errors['email']}
                                           label={"EMAIL"}
                                           value={this.state.email}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_email_tooltip")} />}
                                           onChange={e => this.setState({email: e.target.value})}
                                           name={"name"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['plz']}
                                           label={t("plz")}
                                           value={this.state.plz}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_plz_tooltip")} />}
                                           onChange={e => this.setState({plz: e.target.value})}
                                           name={"plz"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['ort']}
                                           label={t("ort")}
                                           value={this.state.ort}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_ort_tooltip")} />}
                                           onChange={e => this.setState({ort: e.target.value})}
                                           name={"ort"}/>
                                </div>
                                <div className="col-lg-12">
                                    <Select options={i18n.language == 'de' ? this.landOptions : this.landEnOptions}
                                            required
                                            onChange={e => this.setState({land_id: e.target.value})} name="land_id"
                                            value={this.state.land_id}
                                            label={ t("land") }/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"password"}
                                           error={this.state.errors['password']}
                                           required
                                           label={ t("password") }
                                           value={this.state.password}
                                           iconOrientation={"right"}
                                           icon={<IconPassword/>}
                                           onChange={e => this.setState({password: e.target.value})}
                                           name={"password"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"password"}
                                           error={this.state.errors['password_repeat']}
                                           required
                                           label={ t("password_repeat") }
                                           value={this.state.password_repeat}
                                           iconOrientation={"right"}
                                           icon={<IconPassword/>}
                                           onChange={e => this.setState({password_repeat: e.target.value})}
                                           name={"password_repeat"}/>


                                </div>
                                <div className="col-lg-12">
                                    <div className="form-actions mb-3" >
                                        <button  type="submit" className="btn btn--biggest btn--primary btn--add"><i
                                            className="ico icon-cancel-circle" ></i>{ t("register") }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { this.state.loading ? <LoadOverlay /> : null}
        </div>
    }
}


export default withTranslation()(RegisterUserForm);
