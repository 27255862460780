import React, {Component} from "react";
import { default as ReactCheckbox } from "react-custom-checkbox";
import Checkbox from "./form_elements/Checkbox";
import Input from "./form_elements/Input";
import IconTooltip from "./form_elements/IconTooltip";
import api from "../util/api";
import axios from "axios";
import i18n from "../i18n";
import Select from "./form_elements/Select";
import ReactSwitch from 'react-switch'
import InputDate from "./form_elements/InputDate";
import notify from '../util/notfication';
import { withTranslation } from 'react-i18next';
import FormElementError from "./form_elements/FormElementError";
import { RouteComponentProps, withRouter} from "react-router";
import LoadOverlay from "../components/LoadOverlay";
import { useTranslation, Trans } from 'react-i18next';

const championklasse = 6;
const gebrauchshundeklasse = 5;



interface RegistrationProcessProps {
    loading: boolean,
    optionsReceived: boolean,
    isUserFromAustria: boolean,
    id?: string,
    hundename: string,
    urspruengliche_zuchtbuchnummer: string,
    vatername: string,
    muttername: string,
    shortcutlist: string,
    zuechtername: string,
    geschlecht: string,
    datei: File | null,
    champion: File | null,
    reg_dog: boolean | null,
    junior: boolean,
    junior_name: string,
    junior_strasse: string,
    junior_gebdatum: string,
    junior_plz: string,
    junior_ort: string,
    junior_land_id: number | null,
    book_no: string,
    registrationData: {},
    paarklasse: boolean,
    zuchtgruppe: boolean,
    zuchtgruppe_name: string,
    tierschutz: boolean,
    accept_terms: boolean,
    datenschutz: boolean,
    email_allowed: boolean
    ausstellungTitle: string,
    wurfdatum: Date | null,
    rasse_id: number | null,
    klasse_id: number | null,
    errors: {},
}

interface CreateAnmeldungResponse {
    anmeldung: {
        id: number
    }
}

interface AnmeldungPrefill  {
    id: string ,
    geschlecht: string,
    muttername: string,
    zuechtername: string,
    vatername: string,
    wurfdatum: Date | null,
    shortcutlist: string,
    book_no: string,
    reg_dog: boolean | null,
    rasse_id: number | null,
    urspruengliche_zuchtbuchnummer: string,
    hundename: string
}

type RegisterPathParamsType = {
    anmeldung_id?: string,
    ausstellung_id: string,
}

type RegistrationFormProps = {
    prefill?: AnmeldungPrefill,
    editForm?: boolean
    t: Function
}

type RegisterProps = RouteComponentProps<RegisterPathParamsType>;
class RegistrationForm extends Component<RegisterProps & RegistrationFormProps, RegistrationProcessProps> {
    shortcutListOptions: {label: string, value: string}[] = [];
    rasseOptions = [];
    klasseOptions = [];
    landEnOptions = [];
    landOptions = [];
    championklasse = championklasse;
    gebrauchshundeklasse = gebrauchshundeklasse;

    constructor(props) {
        super(props);
        this.state = {
            isUserFromAustria: true,
            optionsReceived: false,
            loading: false,
            registrationData: {}, // all form data goes here
            errors: {},
            hundename: '',
            geschlecht: 'R',
            urspruengliche_zuchtbuchnummer: '',
            vatername: '',
            ausstellungTitle: '',
            muttername: '',
            zuechtername: '',
            shortcutlist: '',
            book_no: '',
            datei: null,
            champion: null,
            reg_dog: false,
            paarklasse: false,
            zuchtgruppe: false,
            zuchtgruppe_name: '',
            junior: false,
            junior_name: '',
            junior_gebdatum: '',
            junior_strasse: '',
            junior_plz: '',
            junior_ort: '',
            wurfdatum: null,
            rasse_id: null,
            klasse_id: null,
            junior_land_id: null,
            tierschutz: false,
            accept_terms: false,
            datenschutz: false,
            email_allowed: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    renderZuchtbuchError = (errors) => {
        if (errors["shortcutlist"]) {
            return <FormElementError message={this.state.errors["shortcutlist"]}/>
        }

        if (errors["book_no"]) {
            return <FormElementError message={this.state.errors["book_no"]}/>
        }
    }

    componentDidUpdate(): void {
        if (this.props.prefill && this.props.prefill.hasOwnProperty('id') && this.props.prefill.id && this.props.prefill.id != this.state.id) {
            this.setRasseOptions(this.props.prefill.rasse_id);
            this.setState({...this.props.prefill});
        }
    }

    setRasseOptions(rasse_id) {
        const shortcutList = JSON.parse(localStorage.getItem('shortcutList') as string);
        const shortcutOptions = shortcutList[rasse_id].map(function($item) {
            return {label: $item, value: $item};
        })

        this.shortcutListOptions = shortcutOptions;
    }

    async componentDidMount() {
        let ausstellung_id = this.props.match.params.ausstellung_id
        const formData = await api().get(`anmeldung/form-data?ausstellung_id=${ausstellung_id}`);
        this.landOptions = formData.data.land;
        this.landEnOptions = formData.data.land_en;
        this.klasseOptions = formData.data.klasse;
        let championklasseoption = this.klasseOptions && this.klasseOptions.find(klasse => klasse['label'] === 'Championklasse') ?
            this.klasseOptions.find(klasse => klasse['label'] === 'Championklasse') : null;
        if (championklasseoption && championklasseoption['value'] && championklasseoption['value'] != championklasse) this.championklasse =  championklasseoption['value'];
        let gebrauchshundeklasseoption = this.klasseOptions && this.klasseOptions.find(klasse => klasse['label'] === 'Gebrauchshundeklasse') ?
            this.klasseOptions.find(klasse => klasse['label'] === 'Gebrauchshundeklasse') : null;
        if(gebrauchshundeklasseoption && gebrauchshundeklasseoption['value'] && gebrauchshundeklasseoption['value'] != gebrauchshundeklasse) this.gebrauchshundeklasse = gebrauchshundeklasseoption['value'];
        this.rasseOptions = formData.data.rasse;
        let userLand = formData.data.user_land;
        let isUserFromAustria = userLand?.id == 40;
        localStorage.setItem('shortcutList', JSON.stringify(formData.data.rasse_shortlist) );

        if(this.props.editForm) {
            let ausstellung_id = this.props.match.params.ausstellung_id
            let anmeldung_id = this.props.match.params.anmeldung_id
            let editData = await api().get(`ausstellung/${ausstellung_id}/anmeldung/${anmeldung_id}/edit-data`);
            let dataProcessed = editData.data;

            dataProcessed = (({
                geschlecht,
                muttername,
                zuechtername,
                vatername,
                wurfdatum,
                zuchtbuchnummer,
                rasse_id,
                paarklasse,
                zuchtgruppe,
                klasse_id,
                urspruengliche_zuchtbuchnummer,
                hundename,
                accept_terms,
                tierschutz,
                datenschutz,
                email_allowed,
                junior,
                junior_gebdatum,
                junior_land_id,
                junior_name,
                junior_strasse,
                junior_ort,
                junior_plz,
                zuchtgruppe_name

            }) => {
                let parts = zuchtbuchnummer.match(/[\w|Č|Ö]+[\/]?(\w*[\/]?\d*)\s?(\d*)?[\/]?(Reg)?/);


                let shortcutlist = parts && parts[1] ? parts[1] : '';
                let book_no = parts && parts[2] ? parts[2] : '';
                let reg_dog = parts && parts[3] ? true : false;
                junior = junior == 1 ? true : false;

                return ({
                    geschlecht,
                    muttername,
                    zuechtername,
                    vatername,
                    shortcutlist,
                    book_no,
                    reg_dog,
                    accept_terms,
                    paarklasse,
                    zuchtgruppe,
                    zuchtbuchnummer,
                    rasse_id,
                    klasse_id,
                    urspruengliche_zuchtbuchnummer,
                    hundename,
                    wurfdatum: new Date(wurfdatum),
                    tierschutz,
                    datenschutz,
                    email_allowed,
                    junior,
                    junior_land_id,
                    junior_gebdatum: new Date(junior_gebdatum),
                    junior_name,
                    junior_strasse,
                    junior_ort,
                    junior_plz,
                    zuchtgruppe_name
                })
            }

            ) (dataProcessed)
            // anmeldungList[dataId].wurfdatum = new Date(anmeldungList[dataId].wurfdatum);

            this.setRasseOptions(dataProcessed.rasse_id);
            this.setState({optionsReceived: true, isUserFromAustria, ...dataProcessed});
        } else {
            // @todo place default values here
            this.setState({optionsReceived: true, isUserFromAustria});
        }


    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ errors: {}, loading: true });

        const { t } = this.props;

        let ausstellung_id = this.props.match.params.ausstellung_id
        let anmeldung_id = this.props.match.params.anmeldung_id

        let URL = `/ausstellung/${ausstellung_id}/anmeldung/create`;
        if(this.props.editForm) {
            URL = `/ausstellung/${ausstellung_id}/anmeldung/${anmeldung_id}/update`
        }

        let formData = new FormData();
        try {

            Object.entries(this.state).map(([key, value]) => {
                if (typeof value == 'boolean') {
                    formData.append(key, value.toString());
                    // console.log(key, value.toString());
                }
                if (typeof value == 'string') {
                    formData.append(key, value);
                }

                if (typeof value == 'number') {
                    formData.append(key, value.toString());
                    // console.log(key, value.toString());
                }

                if (value instanceof Date) {
                    formData.append(key, value.toLocaleDateString('de'));
                }

                if (value instanceof File) {
                    formData.append(key, value, value.name);
                    // console.log(value)
                }

            });

            const response = await api().post<CreateAnmeldungResponse>(URL, formData);
            this.setState({ loading: false });

            notify(t('data_saved'), 'success')

            let anmeldung_id = response.data.anmeldung.id;
            window.location.href = `/ausstellung/${ausstellung_id}/anmeldung/${anmeldung_id}/overview`;
        } catch (error: any) {
            let errors = {};
            if (error.response && error.response.data.errors) {
                notify(t('validation_error'), 'danger');
                for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                    errors[fieldName] = message.pop();
                }
            } else if (error.response && error.response.data.error !== undefined) {
                notify(error.response.data.error, 'danger');
            }
            this.setState({errors: errors, loading: false});
        }
    }

    render() {
        const { t } = this.props;

        return (<div>
            <form className={"form"} autoComplete="off" onSubmit={this.onSubmit}>
                {/*GENERAL INFO*/}

                <div className="row">
                    <div className="col-lg-6">
                        <Input
                            type={"text"}
                            error={this.state.errors["hundename"]}
                            label={ t("hundename") }
                            required
                            value={this.state.hundename}
                            iconOrientation={"right"}
                            icon={<IconTooltip tooltip={ t("hundename_tooltip") } />}
                            onChange={(e) =>
                                this.setState({ hundename: e.target.value })
                            }
                            name={"hundename"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Select
                            error={this.state.errors["rasse_id"]}
                            options={this.rasseOptions}
                            tooltip={ t("rasse_tooltip") }
                            onChange={(e) => {
                                    this.setRasseOptions(e.target.value);
                                    this.setState({ rasse_id: e.target.value, shortcutlist: this.shortcutListOptions[0].value });
                                }
                            }
                            name="rasse_id"
                            value={this.state.rasse_id}
                            label={ t("rasse") }
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="form-element-wrapper">
                            <label className={"required"}>{ t("geschlecht") }</label>
                            <div className="input-group mb-3">
                                <div className="radios">
                                    <div
                                        onClick={(e) =>
                                            this.setState({ geschlecht: "R" })
                                        }
                                        className={
                                            this.state.geschlecht == "R"
                                                ? "radio-button radio-button--checked"
                                                : "radio-button"
                                        }
                                        data-geschlecht="R"
                                    >
                                        { t("ruede") }
                                    </div>
                                    <div
                                        onClick={(e) =>
                                            this.setState({ geschlecht: "H" })
                                        }
                                        className={
                                            this.state.geschlecht == "H"
                                                ? "radio-button radio-button--checked"
                                                : "radio-button"
                                        }
                                        data-geschlecht="H"
                                    >
                                        { t("huendin") }
                                    </div>
                                </div>
                                <IconTooltip tooltip={ t("geschlecht_tooltip") } />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <InputDate
                            name="wurfdatum"
                            error={this.state.errors["wurfdatum"]}
                            label={ t("wurfdatum") }
                            tooltip={ t("wurfdatum_tooltip") }
                            value={this.state.wurfdatum}
                            onChange={(date) =>
                                this.setState({ wurfdatum: date })
                            }
                        />
                    </div>
                    <div className="col-lg-6">
                        <div
                            className={ "form-element-wrapper" }
                        >
                            <label className={"required"} htmlFor="plz">
                                { !this.state.isUserFromAustria ? t("zuchtbuchnummer") : t("zuchtbuchnummer_ohzb") }
                            </label>
                            <div className="row">
                                {this.state.isUserFromAustria ? <div className={this.state.errors["shortcutlist"] ? "col-lg-3 has-error" : "col-lg-3" }>
                                    <Select
                                        options={this.shortcutListOptions}
                                        onChange={(e) =>
                                            this.setState({
                                                shortcutlist: e.target.value,
                                            })
                                        }
                                        rawMarkup={true}
                                        name="shortcutlist"
                                        value={this.state.shortcutlist}
                                    />
                                </div> : null}
                                {this.state.isUserFromAustria ?
                                <div className={
                                    this.state.errors["book_no"] ? "has-error col-lg-3" : "col-lg-3"
                                    }>
                                    <input
                                        type="text"
                                        name="book_no"
                                        value={this.state.book_no}
                                        onChange={(e) =>
                                            this.setState({
                                                book_no: e.target.value,
                                            })
                                        }

                                        className="form-control"
                                    ></input>
                                </div> :
                                <div className="col-lg-12">
                                    <div className={
                                        this.state.errors["book_no"] ? "has-error input-group" : "input-group"
                                        }>
                                        <input
                                            type="text"
                                            name="book_no"
                                            value={this.state.book_no}
                                            onChange={(e) =>
                                                this.setState({
                                                    book_no: e.target.value,
                                                })
                                            }

                                            className="form-control"
                                        ></input>

                                    <IconTooltip tooltip={ t("zuchtbuchnummer_tooltip") } />
                                    </div>
                                </div>
                                }

                                {!this.state.isUserFromAustria ? null : <div className="col-lg-6">
                                    <div className="input-group">
                                        <div className="form-control-checkbox">
                                            <ReactCheckbox
                                                className={"input__checkbox"}
                                                borderRadius={0}
                                                size={30}
                                                checked={this.state.reg_dog}
                                                borderWidth={3}
                                                icon={
                                                    <div>
                                                        <i className="icon-checkmark"></i>
                                                    </div>
                                                }
                                                borderColor="#7F7F7F"
                                                style={{
                                                    backgroundColor: "#DDDDDD",
                                                }}
                                                label={"Register"} // "Ich erkläre mich einverstanden die gültigen Tierschutzbestimmungen einzuhalten."
                                                name={"reg_dog"} // "accept_rules"       "field-label required"
                                                labelClassName={"field-label"}
                                                onChange={(value) => {
                                                    return this.setState({
                                                        reg_dog: value,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <IconTooltip tooltip={ t("zuchtbuchnummer_tooltip") } />
                                    </div>
                                </div>}
                                <div className="col-lg-12">
                                    {this.renderZuchtbuchError(this.state.errors)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <Input
                            type={"text"}
                            error={
                                this.state.errors[
                                    "urspruengliche_zuchtbuchnummer"
                                ]
                            }
                            label={ t("u_zuchtbuchnummer") }
                            value={this.state.urspruengliche_zuchtbuchnummer}
                            iconOrientation={"right"}
                            icon={<IconTooltip tooltip={ t("u_zuchtbuchnummer_tooltip") } />}
                            onChange={(e) =>
                                this.setState({
                                    urspruengliche_zuchtbuchnummer:
                                        e.target.value,
                                })
                            }
                            name={"urspruengliche_zuchtbuchnummer"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Input
                            type={"text"}
                            error={this.state.errors["vatername"]}
                            label={ t("vatername") }
                            required
                            value={this.state.vatername}
                            iconOrientation={"right"}
                            icon={<IconTooltip tooltip={ t("vatername_tooltip") } />}
                            onChange={(e) =>
                                this.setState({ vatername: e.target.value })
                            }
                            name={"vatername"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Input
                            type={"text"}
                            error={this.state.errors["muttername"]}
                            label={ t("muttername") }
                            required
                            value={this.state.muttername}
                            iconOrientation={"right"}
                            icon={<IconTooltip tooltip={ t("muttername_tooltip") } />}
                            onChange={(e) =>
                                this.setState({ muttername: e.target.value })
                            }
                            name={"muttername"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Input
                            type={"text"}
                            error={this.state.errors["zuechtername"]}
                            label={ t("zuechter_name") }
                            required
                            value={this.state.zuechtername}
                            iconOrientation={"right"}
                            icon={<IconTooltip tooltip={ t("zuechtername_tooltip") } />}
                            onChange={(e) =>
                                this.setState({ zuechtername: e.target.value })
                            }
                            name={"zuechtername"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <Select
                            error={this.state.errors["klasse_id"]}
                            options={this.klasseOptions}
                            tooltip={ t("klasse_tooltip") }
                            onChange={(e) =>
                                this.setState({ klasse_id: e.target.value })
                            }
                            name="klasse_id"
                            value={this.state.klasse_id}
                            label={ t("klasse") }
                        />
                    </div>
                </div>

                {/*UPLOAD INFO*/}
                {(this.state.klasse_id && (this.state.klasse_id == this.gebrauchshundeklasse || this.state.klasse_id == this.championklasse)) ? (
                        <div className="row">
                            <div className="col-lg-12">
                                <span className="upload-label">{ t("dateiupload") }</span>
                                <div className="info--gray mb-5 ">
                                    { t("dateiupload_info") }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-element-wrapper file-input__wrapper">
                            <span className="file-input__label">
                                 { t("championzertifikat") }
                            </span>
                                    <div className="file-input" data-toggle="tooltip" data-placement="left" title={ t("dateiupload_tooltip") }>
                                        <input
                                            className="form-control file-input__input"
                                            type="file"
                                            id="champion"
                                            name="champion"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement> )=> {
                                                if (event.target && event.target.files) {
                                                    this.setState({champion: event.target.files[0]});
                                                }
                                            }}
                                        />
                                        <label
                                            className="file-input__element"
                                            data-content={ t("select_file") }
                                            htmlFor="champion"
                                        >
                                            {this.state.champion ? this.state.champion.name : t("zertifikat")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                )
                    : (
                        ""
                    )
                }

                {/*WEITERE OPTIONEN*/}
                <div className="row mt-5 mb-5">
                    <div className="col-lg-12">
                        <span className="weitere-label">{ t("further_options") }</span>
                    </div>
                    <div className="col-lg-6">
                        <div className="input-group">
                            <div className="form-control-checkbox">
                                <ReactCheckbox
                                    className={"input__checkbox"}
                                    borderRadius={0}
                                    size={30}
                                    checked={this.state.paarklasse}
                                    borderWidth={3}
                                    icon={
                                        <div>
                                            <i className="icon-checkmark"></i>
                                        </div>
                                    }
                                    borderColor="#7F7F7F"
                                    style={{ backgroundColor: "#DDDDDD" }}
                                    label={t("paarklasse")} // "Ich erkläre mich einverstanden die gültigen Tierschutzbestimmungen einzuhalten."
                                    name={"paarklasse"} // "accept_rules"       "field-label required"
                                    labelClassName={"field-label"}
                                    onChange={(value) => {
                                        return this.setState({
                                            paarklasse: value,
                                        });
                                    }}
                                />
                            </div>
                            <IconTooltip tooltip={ t("paarklasse_tooltip") } />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="row ">
                            <div className="col-lg-6 ">
                                <div className="input-group">
                                    <div className="form-control-checkbox">
                                        <ReactCheckbox
                                            className={"input__checkbox"}
                                            borderRadius={0}
                                            size={30}
                                            checked={this.state.zuchtgruppe}
                                            borderWidth={3}
                                            icon={
                                                <div>
                                                    <i className="icon-checkmark"></i>
                                                </div>
                                            }
                                            borderColor="#7F7F7F"
                                            style={{
                                                backgroundColor: "#DDDDDD",
                                            }}
                                            label={t("zuchtgruppe")} // "Ich erkläre mich einverstanden die gültigen Tierschutzbestimmungen einzuhalten."
                                            name={"zuchtgruppe"} // "accept_rules"       "field-label required"
                                            labelClassName={"field-label"}
                                            onChange={(value) => {
                                                return this.setState({
                                                    zuchtgruppe: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="zuchtgruppe_name"
                                        onChange={(e) =>
                                            this.setState({
                                                zuchtgruppe_name:
                                                    e.target.value,
                                            })
                                        }
                                        value={this.state.zuchtgruppe_name}
                                        className="form-control"
                                    ></input>
                                    <IconTooltip tooltip={ t("zuchtgruppe_tooltip") } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*JUNIOR INFO*/}
                <div className="row">
                    <div className="col-lg-12">
                        <label htmlFor="disabled-switch">
                            <div className="switch-wrapper" data-toggle="tooltip" data-placement="left" title={ t("junior_tooltip") }>
                                <span className="switch-label">
                                    { t("juniorhandling") }
                                </span>
                                <ReactSwitch
                                    onChange={(value) =>
                                        this.setState({ junior: value })
                                    }
                                    checked={this.state.junior}
                                    handleDiameter={32}
                                    height={40}
                                    offColor="#DDDDDD"
                                    onColor="#7F7F7F"
                                    width={88}
                                    checkedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                paddingLeft: "8px",
                                                color: "#fff",
                                            }}
                                        >
                                            { t("ja") }
                                        </div>
                                    }
                                    uncheckedIcon={
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                color: "#fff",
                                            }}
                                        >
                                            { t("nein") }
                                        </div>
                                    }
                                    className="react-switch"
                                    id="disabled-switch"
                                />
                            </div>
                        </label>
                    </div>
                </div>
                {this.state.junior ? (
                    <div className="row junior-form">
                        <div className="col-lg-6">
                            <Input
                                type={"text"}
                                error={this.state.errors["junior_name"]}
                                label={ t("first_last_name") }
                                value={this.state.junior_name}
                                required
                                iconOrientation={"right"}
                                icon={<IconTooltip tooltip={ t("junior_name_tooltip") } />}
                                onChange={(e) =>
                                    this.setState({
                                        junior_name: e.target.value,
                                    })
                                }
                                name={"junior_name"}
                            />
                        </div>
                        <div className="col-lg-6">
                            <InputDate
                                name="junior_gebdatum"
                                error={this.state.errors["junior_gebdatum"]}
                                label={ t("geburtsdatum") }
                                tooltip={ t("junior_gebdatum_tooltip") }
                                value={this.state.junior_gebdatum}
                                onChange={(date) =>
                                    this.setState({ junior_gebdatum: date })
                                }
                            />
                        </div>

                        <div className="col-lg-6">
                            <Input
                                type={"text"}
                                error={this.state.errors["junior_strasse"]}
                                required
                                label={ t("strasse") }
                                value={this.state.junior_strasse}
                                iconOrientation={"right"}
                                icon={<IconTooltip tooltip={ t("junior_address_tooltip") } />}
                                onChange={(e) =>
                                    this.setState({
                                        junior_strasse: e.target.value,
                                    })
                                }
                                name={"junior_strasse"}
                            />
                        </div>

                        <div className="col-lg-6">
                            <div
                                className={
                                    (this.state.errors["junior_plz"] != null &&
                                        this.state.errors["junior_plz"] !=
                                            "") ||
                                    (this.state.errors["junior_ort"] != null &&
                                        this.state.errors["junior_ort"] != "")
                                        ? "form-element-wrapper has-error"
                                        : "form-element-wrapper"
                                }
                            >
                                <label className="required" htmlFor="plz">
                                { t("plz_ort") }
                                </label>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <input
                                            type="text"
                                            name="junior_plz"
                                            onChange={(e) =>
                                                this.setState({
                                                    junior_plz: e.target.value,
                                                })
                                            }
                                            value={this.state.junior_plz}
                                            className="form-control"
                                        ></input>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                name="junior_ort"
                                                onChange={(e) =>
                                                    this.setState({
                                                        junior_ort:
                                                            e.target.value,
                                                    })
                                                }
                                                value={this.state.junior_ort}
                                                className="form-control"
                                            ></input>
                                            <IconTooltip tooltip={ t("junior_plz_ort_tooltip") }/>
                                        </div>
                                    </div>
                                </div>
                                {(this.state.errors["junior_plz"] != null &&
                                    this.state.errors["junior_plz"] != "") ||
                                (this.state.errors["junior_ort"] != null &&
                                    this.state.errors["junior_ort"] != "") ? (
                                        this.state.errors["junior_ort"] ? <FormElementError
                                        message={this.state.errors["junior_ort"]}
                                    /> : <FormElementError
                                        message={this.state.errors["junior_plz"]}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <Select
                                error={this.state.errors["junior_land_id"]}
                                options={i18n.language == 'de' ? this.landOptions : this.landEnOptions}
                                tooltip={ t("junior_land_tooltip") }
                                onChange={(e) =>
                                    this.setState({
                                        junior_land_id: e.target.value,
                                    })
                                }
                                name="junior_land_id"
                                value={this.state.junior_land_id}
                                label={ t("land") }
                            />
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {/* CHECK BOXES*/}
                <div className="accept-checkboxes">
                    <Checkbox
                        label={<Trans i18nKey={"accept_terms"}>
                            <a target="_blank" onClick={(e) => {e.stopPropagation()}} href='https://www.oekv.at/media/upload/editor/files/%C3%96KV/Downloads/Ausstellungsordnung%C3%96KV2018_01.pdf'></a>
                            <a target="_blank" onClick={(e) => {e.stopPropagation()}}  href='https://www.oekv.at/media/upload/editor/files/%C3%96KV/Downloads/Ausstellungsordnung%C3%96KV2018_01.pdf'></a>)
                        </Trans>}
                        name={"accept_terms"}
                        error={this.state.errors["accept_terms"]}
                        checked={this.state.accept_terms}
                        labelClassName={"field-label required"}
                        onChange={(value) => {
                            return this.setState({ accept_terms: value });
                        }}
                    />

                    <Checkbox
                        label={t("tierschutz")}
                        name={"tierschutz"}
                        error={this.state.errors["tierschutz"]}
                        checked={this.state.tierschutz}
                        labelClassName={"field-label required"}
                        onChange={(value) => {
                            return this.setState({ tierschutz: value });
                        }}
                    />

                    <Checkbox
                        label={t("datenschutz")}
                        name={"datenschutz"}
                        error={this.state.errors["datenschutz"]}
                        checked={this.state.datenschutz}
                        labelClassName={"field-label required"}
                        onChange={(value) => {
                            return this.setState({ datenschutz: value });
                        }}
                    />

                    <Checkbox
                        label={t("email_allowed")}
                        name={"email_allowed"}
                        error={this.state.errors["email_allowed"]}
                        checked={this.state.email_allowed}
                        labelClassName={"field-label"}
                        onChange={(value) => {
                            return this.setState({ email_allowed: value });
                        }}
                    />
                </div>

                <div className="form-actions">
                    <button className="btn btn--biggest btn--primary btn--add">
                        <i className="ico icon-cancel-circle"></i> { t("register_button") }
                    </button>
                </div>

            </form>
            { this.state.loading ? <LoadOverlay /> : null}
            </div>
        );
    }
}


export default withTranslation()(withRouter(RegistrationForm));
