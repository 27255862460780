import React, {useState} from "react";
import api from "../util/api";


interface AusstellungData {
    name: string,
}

const AusstellungHeader = (props) => {
    const [ data, setData] = useState<AusstellungData>();

    const loadAusstellungData = async () => {
        const response: { data: AusstellungData } = await api().get(`ausstellung/data?ausstellung_id=${props.ausstellung_id}`);
        let data = {...response.data};
        setData(data);
    }

    React.useEffect(() => {
        loadAusstellungData();
    }, []);


    return <span className={"process__ausstellung-title"}>{ data?.name }</span>


}

export default AusstellungHeader;
