import React, {Component, useState} from "react";
import api from "../util/api";
import axios from "axios";
import PrefillRegistrationItem from "./PrefillRegistrationItem";
import { useTranslation } from 'react-i18next';

interface PrefillRegistrationProps {

}


interface AnmedlungInfo {
    id: string | number,
    zuchtbuchnummer: string,
    geschlecht: string,
    muttername: string,
    zuechtername: string,
    vatername: string,
    wurfdatum: string,
    rasse: {
        id: number
    },
    urspruengliche_zuchtbuchnummer: string,
    hundename: string
}

const PrefillRegistration = ({ handlePrefill }) => {
    const [ data, setData] = useState([] as AnmedlungInfo[]);
    const loadData = async () => {
        const response: { data: AnmedlungInfo[] } = await api().get(`anmeldung/prefill/data`);
        let anmeldungList: {}[] = [];
        let is_from_austria = localStorage.getItem('user_from_austria');

        response.data.map(({id, hundename, zuchtbuchnummer, geschlecht, wurfdatum, urspruengliche_zuchtbuchnummer, muttername, vatername, zuechtername, rasse}, idx) => {
            const formattedWurfdatum = new Date(wurfdatum);
            if (is_from_austria == 'true') {

                if (zuchtbuchnummer == null) {
                    zuchtbuchnummer = '';
                }

                let matches = zuchtbuchnummer.match(/[\w|Č|Ö]+[\/]?(\w*[\/]?\d*)\s?(\d*)?[\/]?(Reg)?/);

                anmeldungList[idx] = {
                    id,
                    hundename,
                    geschlecht,
                    wurfdatum: formattedWurfdatum,
                    vatername,
                    zuechtername,
                    muttername,
                    urspruengliche_zuchtbuchnummer,
                    shortcutlist: matches  ? matches[1] : '',
                    book_no: matches ? matches[2] : '' ,
                    reg_dog: matches ? matches[3] !== undefined : false,
                    rasse_id: rasse ? rasse.id : '',
                }
            } else {
                let matches = zuchtbuchnummer.match(/(.*)[\/](Reg)?/);

                anmeldungList[idx] = {
                    id,
                    hundename,
                    geschlecht,
                    wurfdatum: formattedWurfdatum,
                    vatername,
                    zuechtername,
                    muttername,
                    urspruengliche_zuchtbuchnummer,
                    shortcutlist: '',
                    book_no: matches ? matches[1] : '' ,
                    reg_dog: matches ? matches[2] !== undefined : false,
                    rasse_id: rasse.id,
                }
            }
        });

        localStorage.setItem('anmeldung_list', JSON.stringify(anmeldungList) );
        setData(response.data);
    };

    React.useEffect(() => {
        loadData();
    }, []);

    const { t } = useTranslation();

    if (data.length > 0) {
        return <div>
            <h4 className={"sidebar__title"}>{ t("anmeldungsarchiv") }</h4>
            <div className={"sidebar__info"}>{ t("anmeldungsarchiv_info") }</div>
            {/*  LIST OF ALL ANMELDUNGEN (Previous registration) */}
            <div className="sidebar__content">
                <div className="anmeldung-prefill-list">
                    {data.map((item, idx) => {
                        return <PrefillRegistrationItem
                            key={idx}
                            zuchtbuchnummer={item.zuchtbuchnummer}
                            id={idx}
                            onClick={handlePrefill}
                            hundename={item.hundename}/>
                    })}
                </div>
            </div>
        </div>;
    } else {
        return <div>
            <h4 className={"sidebar__title"}>{ t("anmeldungsarchiv") }</h4>
            <div className={"sidebar__info"}>{ t("anmeldungsarchiv_no_dogs") }</div>
        </div>
    }


}


export default PrefillRegistration;
