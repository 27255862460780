import IconTooltip from "./IconTooltip";
import React from "react";
import DatePicker from "react-datepicker";
import FormElementError from "./FormElementError";

const InputDate = (props) => {
    return <div className={props.error != null && props.error != '' ? "form-element-wrapper has-error" : "form-element-wrapper"}>
        <label className={"required"} >{props.label}</label>
        <div className="input-group">
            <DatePicker onChange={props.onChange} name={props.name} dateFormat="dd.MM.yyyy" className="form-control datepicker-field" selected={props.value} />
            <IconTooltip tooltip={props.tooltip} />
        </div>
        { props.error ? <FormElementError message={props.error} /> : '' }
    </div>;
};

export default InputDate;
