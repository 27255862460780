import { t } from "i18next";
import React from "react";
import {useParams} from "react-router";
import AusstellungHeader from "./AusstellungHeader";
import i18n from "../i18n";
import { useTranslation } from 'react-i18next';


const DogRegistrationOverviewHeader = (props) => {
    const { ausstellung_id }: { ausstellung_id: string } = useParams();

    const { t } = useTranslation();

    return <div  className="col-lg-12">
        <div className="row">
            <div className="col-lg-9">
                <h2 className={"process__phase"}>{ t("anmeldung_finish_header") } </h2>
                <AusstellungHeader ausstellung_id={ausstellung_id} />
                <div className="process__phase-info">
                </div>
            </div>
            <div className="col-xl-3 process-overview">
                { i18n.language == 'de' ? <img src="/images/paginationP2.svg" alt=""/> : <img src="/images/paginationP2_en.svg" alt=""/> }
            </div>
        </div>
    </div>
}

export default DogRegistrationOverviewHeader;
