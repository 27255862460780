import React, {Component} from "react";
import {Route, RouteComponentProps, Switch, useParams, withRouter} from "react-router";
import PrefillRegistration from './PrefillRegistration';
import DogRegistrationHeader from "./DogRegistrationHeader";
import DogRegistrationOverviewHeader from "./DogRegistrationOverviewHeader";
import AnmeldungOverview from "./AnmeldungOverview";
import RegistrationForm from "./RegistrationForm";
import DogRegistrationPaymentHeader from "./DogRegistrationPaymentHeader";
import BankInfo from "./BankInfo";
import PaymentInformation from "./PaymentInformation";
import PaymentHeader from "./PaymentHeader";
import LoggedInContext from '../contexts/auth';
import AccessDenied from './AccessDenied'



interface AnmeldungPrefill  {
    id: string,
    geschlecht: string,
    muttername: string,
    zuechtername: string,
    vatername: string,
    wurfdatum: Date | null,
    shortcutlist: string,
    book_no: string,
    reg_dog: boolean,
    rasse_id: number | null,
    urspruengliche_zuchtbuchnummer: string,
    hundename: string
}

interface RegistrationProcessProps {
    prefill: AnmeldungPrefill
}

interface CreateAnmeldungResponse {
    anmeldung: {
        id: number
    }
}

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
    ausstellung_id: string,
}

// Your component own properties
type PropsType = RouteComponentProps<PathParamsType>;

class RegistrationProcess extends Component<PropsType, RegistrationProcessProps> {
    async componentDidMount() {
    }

    handlePrefill = (event: React.ChangeEvent<HTMLElement>) => {
        event.preventDefault();
        const dataId = event.target.attributes['data-id'].value;
        const anmeldungList = JSON.parse(localStorage.getItem('anmeldung_list') as string);


        console.log(anmeldungList);

        anmeldungList[dataId].wurfdatum = new Date(anmeldungList[dataId].wurfdatum);
        this.setState({prefill: anmeldungList[dataId]})
    }

    constructor(props) {
        super(props);

        this.state = {
            prefill: {
                id: '',
                geschlecht: '',
                muttername: '',
                zuechtername: '',
                vatername: '',
                wurfdatum: null,
                shortcutlist: '',
                book_no: '',
                reg_dog: false,
                rasse_id: null,
                urspruengliche_zuchtbuchnummer: '',
                hundename: ''
            },
        }

        this.handlePrefill = this.handlePrefill.bind(this);
    }

    render() {
        return this.context ? <div className="container page">
            {/* HEADING */}
            <div className="row process-page">

                <Switch>
                    <Route exact path="/ausstellung/:ausstellung_id" >
                        <DogRegistrationHeader />
                    </Route>
                    <Route exact path="/user/zahlungen" >
                        <DogRegistrationPaymentHeader />
                    </Route>
                    <Route exact path="/ausstellung/:ausstellung_id/anmeldung/:anmeldung_id/overview" >
                        <DogRegistrationOverviewHeader/>
                    </Route>

                </Switch>

            </div>
            {/* PROCESS*/}
            <div className="row">
                <div className="col-lg-9 main-content-container">
                    <div className="content-block">
                        <Switch>
                            <Route exact path="/ausstellung/:ausstellung_id" >
                                <RegistrationForm prefill={this.state.prefill}/>
                            </Route>
                            <Route exact path="/ausstellung/:ausstellung_id/anmeldung/:anmeldung_id/overview" >
                                <AnmeldungOverview />
                            </Route>
                            <Route exact path="/ausstellung/:ausstellung_id/anmeldung/:anmeldung_id/edit" >
                                <RegistrationForm  editForm />
                            </Route>
                            <Route exact path="/user/zahlungen" >
                                <PaymentInformation />
                            </Route>
                        </Switch>
                    </div>

                </div>
                <div className="col-lg-3 registration-process-container">
                    <Switch>
                        <Route exact path="/ausstellung/:ausstellung_id" >
                            <div className="content-block">
                                <PrefillRegistration handlePrefill={this.handlePrefill}/>
                            </div>
                        </Route>
                        <Route exact path="/user/zahlungen" >
                            <div className="content-block">
                                <BankInfo />
                            </div>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div> : <AccessDenied />
    }
}

RegistrationProcess.contextType = LoggedInContext;

export default withRouter(RegistrationProcess);

