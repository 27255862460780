import React, {Component, SyntheticEvent, useState, useCallback, useContext, useEffect} from "react";
import api from "../util/api";
import MeineAnmeldungList from './MeineAnmeldungList';
import Modal from 'react-modal';
import notify from '../util/notfication';
import Input from "./form_elements/Input";
import LoggedInContext from "../contexts/auth";
import AccessDenied from "./AccessDenied";
import { useTranslation } from 'react-i18next';
import LoadOverlay from "../components/LoadOverlay";

const customStyles = {
    content: {
        width: '600px',
        height: '600px',
        top: 'calc(50% - 580px / 2)',
        left: 'calc(50% - 600px / 2)',
    },
};

const MeineAnmeldungListWrapper = (props) => {
    const [ data, setData] = useState([]);
    const [ loading, setLoading] = useState(false);
    const [ showModal, setShowModal] = useState(false);
    const [ anmeldungId, setAnmeldungId] = useState('');
    const [ hundename, setHundename] = useState('');
    const [ ausstellung, setAusstellung] = useState('');

    const loadData = async () => {
        const response = await api().get(`anmeldung-list-data`);
        setData(response.data);
    };

    const handleOpenModal = (e) => {
        setAnmeldungId(e.target.attributes.getNamedItem("data-anmeldung-id").value);
        setHundename(e.target.attributes.getNamedItem("data-hundename").value);
        setAusstellung(e.target.attributes.getNamedItem("data-ausstellung").value);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const response = await api().post(`anmeldung/delete`, {anmeldung_id: anmeldungId});
        setLoading(false);
        notify('Anmeldung gelöscht', 'success');
        loadData();
        setShowModal(false);
    }

    useEffect(() => {
        loadData();
    }, []);

    const loggedIn = useContext(LoggedInContext);
    const { t } = useTranslation();

    return loggedIn ? <div>
        <Modal
                isOpen={showModal}
                contentLabel="Anmeldung"
                style={customStyles}
                className="login-modal delete-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={handleCloseModal}>
                    <span className="icon-close-modal">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </span>
                </button>

                <div className="row">
                    <div className="col-12 justify-content-center align-items-center text-center anmeldung-delete__info">
                        <p>{t("delete_confirm_1")}
                            <span className="dog-name bold"> {hundename} </span>
                            {t("delete_confirm_2")}
                            <span className="ausstellung-name bold"> {ausstellung} </span>
                            {t("delete_confirm_3")}
                        </p>
                    </div>
                </div>

                <form className="anmeldung-delete__form" onSubmit={onSubmit}>
                    <Input type={"hidden"}
                       required
                       value={anmeldungId}
                       name={"anmeldung_id"} />
                    <div className="anmeldung-delete__buttons-wrapper justify-content-end d-flex">
                        <button onClick={handleCloseModal} className="btn btn--primary btn--big" >{t("cancel_button")}</button>
                        <button type="submit"  className="btn btn--primary btn--big" >{t("confirm_button")}</button>
                    </div>
                </form>
            </Modal>

        <MeineAnmeldungList data={data} openModal={handleOpenModal} />
        { loading ? <LoadOverlay /> : null}
    </div> : <AccessDenied />
}

export default MeineAnmeldungListWrapper;
