import React from 'react';
import Modal from 'react-modal';
import LoginForm from './LoginForm';
import ResetPassForm from './ResetPassForm';
import ImpersonateForm from './ImpersonateForm';
import api from "../util/api";
import { useTranslation } from 'react-i18next';
import { isLoggedIn, logOut, leaveImpersonate } from "../util/auth";
import Cookies from 'js-cookie';
import LanguageSelector from '../components/LanguageSelector';

Modal.setAppElement('#root');

const customStyles = {
    content: {
        width: '600px',
        height: '600px',
        top: 'calc(50% - 580px / 2)',
        left: 'calc(50% - 600px / 2)',
    },
};

const LoginLink = (props) => {
    const { t } = useTranslation();

    if (props.show) {
        return (null)
    }
    return <div className="menu-item d-xs-none">
            <div className="menu-item__content">
                <a className="icon-link" onClick={props.handleOpenModal}>
                    <i className="icon icon-login"></i>
                </a>
                <a onClick={props.handleOpenModal} className="menu-item__link">{t('login')}</a>
            </div>
        </div>

}

const LoginLinkResponsive = (props) => {
    const { t } = useTranslation();

    if (props.show) {
        return (null)
    }
    return <div className="responsive-menu-link dropdown-item">
            <a onClick={props.handleOpenModal} className="resonsive-menu-item__link">{t('login')}</a>
        </div>

}

const LoggedInUserLinkAnmeldung = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="menu-item d-xs-none">
        <div className="menu-item__content">
            <a className="icon-link" href={`/anmeldung-list`}>
                <i className="icon icon-meine-anmeldungen"></i>
            </a>
            <a className="menu-item__link" href={`/anmeldung-list`}>
                {t('my_registrations')}
            </a>
        </div>
        <div className="menu-item__content-additional">
            {props.anmeldungen} {t("registrations")}
        </div>
    </div>
};
const LoggedInResponsiveUserLinkAnmeldung = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="responsive-menu-link dropdown-item">
        <a className="resonsive-menu-item__link"  href={`/anmeldung-list`}>
            {t('my_registrations')}
        </a>
    </div>
};


const LoggedInUserLinkZahlungen = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="menu-item d-xs-none">
        <div className="menu-item__content">
            <a href={"/user/zahlungen"} className="icon-link">
                <i className="icon icon-zahlungen"></i>
            </a>
            <a href={"/user/zahlungen"} className="menu-item__link">{t('payments')}</a>
        </div>
        <div className="menu-item__content-additional menu-item__content-additional--zahlungen">
            € {props.zahlungen} {t("unpaid")}
        </div>
    </div>;
};

const ImpersonateButton = (props) => {
    let impersonateed = !! Cookies.get('hundeausstellung_impersonate');
    const { t } = useTranslation();

    if (!impersonateed) {
        return <div>
            <a className="dropdown-item"
                   href="#"
                   onClick={() => {
                    props.impersonate()
                   }
                }>{ t('impersonate') }</a>
        </div>
    } else {
        return <div>
            <a className="dropdown-item"
                href="#"
                onClick={() => {
                    api().post('leaveImpersonate').then(() => {
                        leaveImpersonate();
                    });
                }
                }>{ t('impersonate_leave') }</a>
        </div>
    }
};

const LoggedInResponsiveUserLinkZahlungen = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }

    return <div className="responsive-menu-link dropdown-item">
        <a className="resonsive-menu-item__link text-uppercase" href={"/user/zahlungen"}>
            {t('payments')}
        </a>
    </div>
};

const LoggedInUserLinkUser = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="menu-item d-xs-none">
        <div className="menu-item__content menu-item__content--expandable btn-group">
            <a className="icon-link" href={`/user/${props.userID}`}>
                <i className="icon icon-login"></i>
            </a>
            <a className="menu-item__link dropdown-toggle" data-bs-toggle="dropdown">{props.fullName}</a>
            <div className="dropdown-menu">
                <a className="dropdown-item" href={`/user/${props.userID}`}>{t("profile")}</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item"
                   href="#"
                   onClick={() => {
                    api().post('logout').then(() => {
                        logOut();
                    });
                   }
                }>{t("logout")}</a>
                {props.canImpersonate ? <ImpersonateButton impersonate={props.impersonate} /> : null}
            </div>
        </div>
        <div className="menu-item__content-additional">
            {/*abmelden*/}
        </div>
    </div>;
};

const LoggedInResponsiveUserLinkUser = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="responsive-menu-link dropdown-item">
        <a className="resonsive-menu-item__link text-uppercase" href={`/user/${props.userID}`}>
            {t("edit_profile")}
        </a>
    </div>
};

const LoggedInResponsiveUserLinkLogout = (props) => {
    const { t } = useTranslation();

    if (!props.show) {
        return (null)
    }
    return <div className="responsive-menu-link dropdown-item">
        <a className="resonsive-menu-item__link"
           href="#"
           onClick={() => {
               api().post('logout').then(() => {
                   logOut();
               });
           }
           }>{t("logout")}
        </a>
    </div>

};




interface UserInfo {
    zahlungen: string;
    fullName: string;
    anmeldungen: number;
    userID: number | null;
    canImpersonate: boolean;
}

interface HeaderProps {
    showModal: boolean,
    showMenu: boolean,
    isLoggedIn: boolean | null,
    modalForm: string,
    redirect: boolean,
    userInfo: UserInfo
}

const ModalForm = (props) => {
    switch (props.form) {
        case 'login':
            return <LoginForm closeModal={props.handleCloseReloadModal} openResetForm={props.openResetForm} openRegisterForm={props.openRegisterForm} />;
        case 'pass':
            return <ResetPassForm closeModal={props.handleCloseModal}/>;
        case 'impersonate':
            return <ImpersonateForm closeModal={props.handleCloseModal}/>;
    }

    return (null);
};

class  Header extends React.Component<{}, HeaderProps> {


    componentDidMount(): void {
        if (this.state.isLoggedIn) {
            api().get('user/info').then(response => {
                localStorage.setItem('user_from_austria', response.data.is_from_austria );
                this.setState({
                    userInfo: {
                        zahlungen: response.data.zahlungen,
                        anmeldungen: response.data.anmeldungen,
                        userID: response.data.userID,
                        fullName: response.data.full_name,
                        canImpersonate: response.data.canImpersonate,
                    }
                });
            }).catch((error) => {

            });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalForm: '',
            isLoggedIn: isLoggedIn(),
            redirect: false,
            showMenu: false,
            userInfo: {
                zahlungen: '',
                anmeldungen: 0,
                userID: null,
                fullName: '',
                canImpersonate: false
            }
        };
        this.handleOpenModalLogin = this.handleOpenModalLogin.bind(this);
        this.handleOpenModalImpersonate = this.handleOpenModalImpersonate.bind(this);
        this.handleOpenModalResetPass = this.handleOpenModalResetPass.bind(this);
        this.handleOpenModalRegister = this.handleOpenModalRegister.bind(this);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCloseReloadModal = this.handleCloseReloadModal.bind(this);
        this.openBurger = this.openBurger.bind(this);
    }

    handleOpenModalLogin () {
        this.setState({ showModal: true, modalForm: 'login' });
    }
    handleOpenModalResetPass () {
        this.setState({  modalForm: 'pass' });
    }
    handleOpenModalRegister () {
        this.setState({ modalForm: 'register' });
    }
    handleOpenModalImpersonate () {
        this.setState({ showModal: true, modalForm: 'impersonate' });
    }


    handleCloseModal () {
        this.setState({ showModal: false });
    }

    handleCloseReloadModal () {
        this.setState({ showModal: false, redirect: true });
    }

    openBurger () {
        this.setState({ showMenu: true });
    }

    render() {
        return <div className="container-fluid header-container">
            <Modal
                isOpen={this.state.showModal}
                contentLabel="Login"
                style={customStyles}
                className="login-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={this.handleCloseModal}>
                    <span className="icon-close-modal">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </span>
                </button>

                <ModalForm form={this.state.modalForm}
                            handleCloseModal={this.handleCloseModal}
                            openResetForm={this.handleOpenModalResetPass}
                            openRegisterForm={this.handleOpenModalRegister} />
            </Modal>
            <div className="row">
                <div className="col-lg-12">
                    <div className="container header">
                        <div className="row">
                            <div className="col-4 logo-col">
                                <a href={"/"}> <img src="/images/logo.png" alt="" className="logo"/> </a>
                                <h2 className="headings">HUNDEAUSSTELLUNGEN</h2>
                            </div>
                            <div className="col-8 menu-col">
                                <LoginLink show={this.state.isLoggedIn} handleOpenModal={this.handleOpenModalLogin}/>

                                <LoggedInUserLinkAnmeldung userID={this.state.userInfo.userID} show={this.state.isLoggedIn} anmeldungen={this.state.userInfo.anmeldungen} />
                                <LoggedInUserLinkZahlungen  show={this.state.isLoggedIn} zahlungen={this.state.userInfo.zahlungen} />
                                <LoggedInUserLinkUser   canImpersonate={Cookies.get('hundeausstellung_impersonate', {expires: 86400, sameSite: 'lax'}) || this.state.userInfo.canImpersonate}
                                                        impersonate={this.handleOpenModalImpersonate}
                                                        show={this.state.isLoggedIn}
                                                        userID={this.state.userInfo.userID}
                                                        fullName={this.state.userInfo.fullName} />

                                <LanguageSelector />
                                <a href="#" className="dropdown-toggle burger-menu-item" data-bs-toggle="dropdown" onClick={this.openBurger}>
                                    <span className="icon-menu"></span>
                                </a>
                                <div className="dropdown-menu">
                                    <LoginLinkResponsive show={this.state.isLoggedIn} handleOpenModal={this.handleOpenModalLogin} />
                                    <LoggedInResponsiveUserLinkAnmeldung userID={this.state.userInfo.userID} show={this.state.isLoggedIn} anmeldungen={this.state.userInfo.anmeldungen} dropdown="dropdown-item" />
                                    <LoggedInResponsiveUserLinkZahlungen  show={this.state.isLoggedIn} zahlungen={this.state.userInfo.zahlungen} dropdown="dropdown-item" />
                                    <LoggedInResponsiveUserLinkUser  show={this.state.isLoggedIn} userID={this.state.userInfo.userID} />
                                    <LoggedInResponsiveUserLinkLogout  show={this.state.isLoggedIn} userID={this.state.userInfo.userID} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    };
};

export default Header;
