import i18n from "../i18n";
import React from "react";
import { useTranslation } from 'react-i18next';

const PaymentHeader = (props) => {
    const { t } = useTranslation();

    return <div  className="col-lg-12">
        <div className="row">
            <div className="col-lg-9">
                <h2 className={"process__phase"}>{ t("payment_header") }</h2>
                <div className="process__phase-info">
                    { t("payment_header_info") }
                </div>
            </div>
            <div className="col-xl-3 process-overview">
                { i18n.language == 'de' ? <img src="/images/paginationP3.svg" alt=""/> : <img src="/images/paginationP3_en.svg" alt=""/> }
            </div>
        </div>
    </div>
}

export default PaymentHeader;
