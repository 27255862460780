import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { Trans } from 'react-i18next';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            /* translation file path */
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'de',
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a', 'b', 'span'],
            transSupportBasicHtmlNodes: true
        }
    })

export default i18n
