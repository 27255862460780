import React, {useState} from "react";
import api from "../util/api";
import {useParams} from "react-router";
import { useTranslation } from 'react-i18next';


interface AnmeldungOverviewData {
    geschlecht: string,
    zuchtbuchnummer: string,
    wurfdatum: string,
    urspruengliche_zuchtbuchnummer: string,
    vatername: string,
    muttername: string,
    zuechtername: string,
    paarklasse: 0 | 1,
    zuchtgruppe: 0 | 1,
    hundename: string,
    rasse: {
        name: string
    },
    klasse: {
        name: string
    },
}

const CheckedField = (props) => {
    return <div onClick={() => { window.scrollTo(0, 0); }}>
        <i className="icon-checkmark" style={{color: "#7F7F7F"}}></i> {props.label}
    </div>
}

const AnmeldungOverview =  (props) => {
    const [ data, setData] = useState({ rasse: {} } as AnmeldungOverviewData);
    const { anmeldung_id, ausstellung_id }: {anmeldung_id: string, ausstellung_id: string} = useParams();

    const { t } = useTranslation();

    const loadData = async () => {
        const response: { data: AnmeldungOverviewData } = await api().get(`anmeldung/overview/form-data?anmeldung_id=${anmeldung_id}`);



        let data = {...response.data};
        if (data.geschlecht) {
            data.geschlecht = data.geschlecht == 'R' ? t("ruede") : t("huendin");
        }
        if (data.wurfdatum) {
            data.wurfdatum = (new Date(data.wurfdatum)).toLocaleDateString('de');
        }

        setData(data);
    };

    React.useEffect(() => {
        loadData();
    }, []);

    return <div className={"anmeldung-overview"}>
        <span className="anmeldung-overview__title">{ t("anmeldung_overview_title") }</span>

        <table className="anmeldung-overview__table">
            <tbody>

                <tr>
                    <td>{ t("hundename") }</td>
                    <td>{ data.hundename }</td>
                </tr>
                <tr>
                    <td>{ t("rasse") }</td>
                    <td>{ data?.rasse?.name }</td>
                </tr>
                <tr>
                <td>{ t("klasse") }</td>
                    <td>{ data?.klasse?.name }</td>
                </tr>
                <tr>
                    <td>{ t("geschlecht") }</td>
                    <td>{ data.geschlecht }</td>
                </tr>
                <tr>
                    <td>{ t("wurfdatum") }</td>
                    <td>{ data.wurfdatum }</td>
                </tr>
                <tr>
                    <td>{ t("zuchtbuchnummer") }</td>
                    <td>{ data.zuchtbuchnummer }</td>
                </tr>
                <tr>
                    <td>{ t("u_zuchtbuchnummer") }</td>
                    <td>{ data.urspruengliche_zuchtbuchnummer }</td>
                </tr>
                <tr>
                    <td>{ t("vatername") }</td>
                    <td>{ data.vatername }</td>
                </tr>
                <tr>
                    <td>{ t("muttername") }</td>
                    <td>{ data.muttername }</td>
                </tr>
                <tr>
                    <td>{ t("zuechter_name") }</td>
                    <td>{ data.zuechtername }</td>
                </tr>
                <tr>
                    <td>{ t("further_options") }</td>
                    <td>{ data.paarklasse === 1 ? <CheckedField label="Paarklasse" /> : ''} { data.zuchtgruppe === 1 ? <CheckedField label="Zuchtgruppe" /> : ''}</td>
                </tr>
            </tbody>
        </table>

        <div className="anmeldung-overview__info">{ t("anmeldung_overview_info") }</div>

        <div className="anmeldung-overview__buttons-wrapper">
            <a href={`/`} className="btn btn--biggest btn--primary btn--add"><i className="ico icon-cancel-circle"></i>{ t("anmeldung_overview_button_1") }</a>
            <a href={`/ausstellung/${ausstellung_id}/anmeldung/${anmeldung_id}/edit`} className="btn btn--biggest btn--primary btn--add btn-middle"><i className="ico icon-cancel-circle"></i>{ t("anmeldung_overview_button_2") } </a>
            <a href={`/user/zahlungen`} className="btn btn--biggest btn--primary btn--add"><i className="ico icon-cancel-circle"></i>{ t("anmeldung_overview_button_3") }</a>
        </div>
    </div>
}


export default AnmeldungOverview;
