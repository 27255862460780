import React, {Component} from 'react';
import api from "../util/api";
import axios from "axios";
import Input from "./form_elements/Input";
import IconUser from "./form_elements/IconUser";
import IconPassword from "./form_elements/IconPassword";
import notify from '../util/notfication';
import { logIn } from '../util/auth'
import { withTranslation } from 'react-i18next';
import LoadOverlay from "../components/LoadOverlay";


interface LoginFormProps {
    loggedIn: boolean,
    loading: boolean,
    errors: {},
    email: string,
    passwort: string,
    redirect: boolean,
}

interface LoginFormContext {
    closeModal: () => void,
    t: Function,
    openResetForm: () => void,
    openRegisterForm: () => void
}

interface LoginResponse  {
    token: string
}


class LoginForm extends Component<LoginFormContext, LoginFormProps> {
    requiredFields = ['email', 'passwort'];
    closeModal: () => void;
    openResetForm: () => void;

    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.openResetForm = props.openResetForm;

        this.state = {
            loggedIn: false,
            loading: false,
            email: '',
            passwort: '',
            errors: {},
            redirect: false
        };
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ errors: {}, loading: true });
        let loginURL = 'login';

        axios.get('/sanctum/csrf-cookie').then(async () => {

                try {
                    let response = await api().post<LoginResponse>(loginURL, {
                        email: this.state.email,
                        passwort: this.state.passwort
                    });

                    if (response.status == 204 || response.status == 200) {
                        this.setState({ loading: false });
                        logIn();
                    }

                } catch (error: any) {
                    let errors: {email: string, password: string, login: string} = {email: '', password: '', login: ''};
                    if (error.response && error.response.data.errors) {
                        notify('Bitte Validierungsfehler beheben', 'danger');
                        for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                            errors[fieldName] = message.pop();
                        }
                    }
                    else if (error.response && error.response.data.error !== undefined) {
                        notify(error.response.data.error, 'danger');
                    }
                    this.setState({errors: errors, loading: false});
                }
        });


    };

    render() {
        const { t } = this.props;

        return <div id="dialog">
            <h3 className='text-uppercase'>{t("login")}</h3>

            <div className="info info--form info--gray">
                {t("login_modal_message")}
            </div>

            <form className="login-form" onSubmit={this.onSubmit} >
                <Input type={"text"}
                    error={ this.state.errors['email'] || this.state.errors['login'] }
                    value={this.state.email}
                    placeholder={"E-mail"}
                    iconOrientation={"left"}
                    icon={ <IconUser/> }
                    onChange={e => this.setState({email: e.target.value})}
                    name={"email"} />

                <Input type={"password"}
                    error={ this.state.errors['passwort'] || this.state.errors['login'] }
                    value={this.state.passwort}
                    iconOrientation={"left"}
                    placeholder={t("password")}
                    icon={ <IconPassword/> }
                    onChange={e => this.setState({passwort: e.target.value})}
                    name={"passwort"} />


                <div className="dialog-links">
                    <a className="link-red"
                       href="#" onClick={this.openResetForm}>{t("forgot_password")}</a>
                    <button type="submit" className="btn btn--primary btn--big text-uppercase" >{t("login_button")}</button>
                </div>

            </form>



            <div className="info info--gray">{t("register_message1")}</div>
            <a className="info info--red" href={"/user/register"}>{t("register_message2")}</a>
            { this.state.loading ? <LoadOverlay /> : null}
        </div>


        // <SpinnerContext.Consumer>
        //     {({ loading, setLoading }) => (

        //     )}

        // </SpinnerContext.Consumer>
    }
}

export default withTranslation()(LoginForm);
