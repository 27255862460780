import React, {Component, SyntheticEvent} from "react";
import Input from "./form_elements/Input";
import IconTooltip from "./form_elements/IconTooltip";
import Select from "./form_elements/Select";
import api from "../util/api";
import { RouteComponentProps, withRouter} from "react-router";
import LoggedInContext from "../contexts/auth";
import AccessDenied from "./AccessDenied";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import LoadOverlay from "../components/LoadOverlay";



interface RegisterUserFormProps {
    name: string,
    vorname: string,
    loading: boolean,
    landOptionsReceived: boolean,
    email: string,
    strasse: string,
    plz: string,
    land_id: number | null,
    ort: string,
    errors: {}
}

type UserPathParamsType = {
    user_id: string
}


interface UserRegisterResponse {

}

type RegisterUserProps = RouteComponentProps<UserPathParamsType>;

class RegisterUserEditForm extends Component<RegisterUserProps & {  t: Function }, RegisterUserFormProps> {
    landOptions = [];
    landEnOptions = [];

    constructor(props) {
        super(props);
        this.state = {
            landOptionsReceived: false,
            loading: false,
            errors: {},
            name: '',
            vorname: '',
            land_id: null,
            plz: '',
            ort: '',
            email: '',
            strasse: '',
        };

    }

    async componentDidMount()  {
        const formData = await api().get('user/register/form-data');
        this.landOptions = formData.data.land;
        this.landEnOptions = formData.data.land_en;


        let user_id = this.props.match.params.user_id;
        const editData = await api().get(`user/${user_id}/edit-data`);


        this.setState({
            landOptionsReceived: true,
            ort: editData.data.ort,
            plz: editData.data.plz,
            strasse: editData.data.strasse,
            land_id: editData.data.land_id,
            name: editData.data.name,
            email: editData.data.email,
        });
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({ errors: {}, loading: true });
        let user_id = this.props.match.params.user_id;
        let URL = `user/${user_id}/updateProfile`;


        try {
            const response = await api().post<UserRegisterResponse>(URL, {
                name: this.state.name,
                strasse: this.state.strasse,
                plz: this.state.plz,
                ort: this.state.ort,
                email: this.state.email,
                land_id: this.state.land_id,
            });

            window.location.href = '/';
        } catch (error: any) {
            let errors: {email: string} = {email: ''};
            if (error.response.data.errors) {
                for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                    errors[fieldName] = message.pop();
                }
            }
            this.setState({errors: errors, loading: false});
        }


    };

    render() {
        const { t } = this.props;

        return this.context ? <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="content-block content-block--centered">
                        <h3 className={"mb-5"}>{ t("edit_profile") }</h3>
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           error={this.state.errors['name']}
                                           label={"NAME"}
                                           required
                                           value={this.state.name}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_name_tooltip")} />}
                                           onChange={e => this.setState({name: e.target.value})}
                                           name={"name"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['strasse']}
                                           label={ t("strasse") }
                                           value={this.state.strasse}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_strasse_tooltip")} />}
                                           onChange={e => this.setState({strasse: e.target.value})}
                                           name={"strasse"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"email"}
                                           error={this.state.errors['email']}
                                           label={"EMAIL"}
                                           disabled
                                           value={this.state.email}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_email_tooltip")} />}
                                           onChange={e => this.setState({email: e.target.value})}
                                           name={"email"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['plz']}
                                           label={t("plz")}
                                           value={this.state.plz}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_plz_tooltip")} />}
                                           onChange={e => this.setState({plz: e.target.value})}
                                           name={"plz"}/>
                                </div>
                                <div className="col-lg-6">
                                    <Input type={"text"}
                                           required
                                           error={this.state.errors['ort']}
                                           label={t("ort")}
                                           value={this.state.ort}
                                           iconOrientation={"right"}
                                           icon={<IconTooltip tooltip={t("user_register_ort_tooltip")} />}
                                           onChange={e => this.setState({ort: e.target.value})}
                                           name={"ort"}/>
                                </div>
                                <div className="col-lg-12">
                                    <Select options={i18n.language == 'de' ? this.landOptions : this.landEnOptions}
                                            required
                                            onChange={e => this.setState({land_id: e.target.value})} name="land_id"
                                            value={this.state.land_id}
                                            label={ t("land") }/>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-actions mb-3" >
                                        <button  type="submit" className="btn btn--biggest btn--primary btn--add"><i
                                            className="ico icon-cancel-circle" ></i>{ t("save_changes") }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            { this.state.loading ? <LoadOverlay /> : null}
        </div> : <AccessDenied/>
    }
}

RegisterUserEditForm.contextType = LoggedInContext;

export default withTranslation()(withRouter(RegisterUserEditForm));
