import React from 'react';
import { useTranslation } from 'react-i18next';

const  Footer = () => {
    const { t } = useTranslation();
    return (
        <div className="container-fluid sub-footer">
            <div className="row">
                <div className="col-lg-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">©2021 – Österreichischer Kynologenverband</div>
                            <div className="col-lg-8 sub-footer-menu-wrapper">
                                <ul className="sub-footer-menu">
                                    <li>
                                        <a target="_blank" rel={"noreferrer"} href="https://www.oekv.at/media/upload/editor/files/%C3%96KV/Downloads/Ausstellungsordnung%C3%96KV2018_01.pdf">{t("exhibition_conditions")}</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.oekv.at/de/kontakt/">{t("contact")}</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.oekv.at/de/impressum/">{t("impressum")}</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://www.oekv.at/de/datenschutzerklaerung/">{t("privacy_policy")}  </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
