import React from "react";
import { useTranslation } from 'react-i18next';

const MeineAnmeldungHeader = (props) => {
    const { t } = useTranslation();

    return <div  className="col-lg-12">
        <div className="row">``
            <div className="col-lg-9">
                <h2 className={"process__phase"}>{ t("my_registrations") }</h2>
                <div className="process__phase-info">
                    { t("my_registrations_info") }
                </div>
            </div>
        </div>
    </div>
}

export default MeineAnmeldungHeader;
