import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import Cookies from "js-cookie";
import {  COOKIE_DOMAIN } from "../constants"

const LanguageSelector = () => {
    const { t } = useTranslation();

    return (
        <div className="menu-item lang-links">
            <div className="menu-item__content">
                <a
                    onClick={(e) => {
                        i18n.changeLanguage("de");
                        Cookies.set("hundeausstellung_lang", "de", { domain: COOKIE_DOMAIN });
                    }}
                    className={
                        i18n.language == "de" ? "lang-link active" : "lang-link"
                    }
                >
                    DE
                </a>
                |
                <a
                    onClick={(e) => {
                        i18n.changeLanguage("en");
                        Cookies.set("hundeausstellung_lang", "en", { domain: COOKIE_DOMAIN });
                    }}
                    className={
                        i18n.language == "en" ? "lang-link active" : "lang-link"
                    }
                >
                    EN
                </a>
            </div>
        </div>
    );
};

export default LanguageSelector;
