import React, {Component} from 'react';
import api from "../util/api";
import notify from '../util/notfication';
import { withTranslation } from 'react-i18next';
import LoadOverlay from "../components/LoadOverlay";

function Error (props) {
    return <span className="error-message">{props.message}</span>;
}

interface ResetPassFormProps {
    errors: {},
    email: string,
    redirect: boolean,
    loading: boolean,
}

class ResetPassForm extends Component<{closeModal: () => void, t: Function}, ResetPassFormProps> {
    closeModal;
    openResetForm;
    openRegisterForm;

    constructor(props) {
        super(props);
        this.closeModal = props.closeModal;
        this.state = {
            email: '',
            loading: false,
            errors: {},
            redirect: false
        };
    }

    onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.setState({ errors: {}, loading: true });
        let forgotPassURL = 'forgot-password';


        try {
            const response = await api().post(forgotPassURL, {
                email: this.state.email,
            });

            this.setState({ loading: false });

            notify(response.data.message, 'success');

            this.closeModal()

        } catch (error: any) {
            let errors: {email: string} = {email: ''};

            if (error.response.data.errors !== undefined) {
                for (const [fieldName, message] of Object.entries<string[]>(error.response.data.errors)) {
                    errors[fieldName] = message.pop();
                }
            } else if (error.response.data.error !== undefined) {
                notify(error.response.data.error, 'danger');
            }
            this.setState({errors: errors, loading: false});
        }


    };

    render() {
        const { t } = this.props;

        return <div id="dialog">
            <h3>{t("reset_pass")}</h3>
            <div className="info info--form info--gray">
                {t("request_password_reset_message")}
            </div>
            <form className="reset-password-form" onSubmit={this.onSubmit} >
                <div className={this.state.errors['email'] === undefined || !this.state.errors['email'].length ? "input-group" : "input-group has-error"}>
                    <span className="input-group-text"><i className="icon icon-user"></i></span>
                    <input type="text" value={this.state.email} onChange={e => this.setState({email: e.target.value})} className="form-control" placeholder={t("your_username") + " (email)"} name="email"></input>
                </div>
                { this.state.errors['email'] === undefined ? '' :  <Error key={"email"} message={this.state.errors['email']} /> }

                <div className="dialog-links">
                    <button type="submit" className="btn btn--primary btn--big" >{ t("reset_pass_button") }</button>
                </div>
            </form>
            { this.state.loading ? <LoadOverlay /> : null}
        </div>
    }
}

export default withTranslation()(ResetPassForm);
