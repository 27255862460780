import React, {useState} from "react";
import api from "../util/api";
import { useTranslation } from 'react-i18next';
import axios from "../util/api";

interface BankPaymentInfo {
    belegnummer: string;
}

const BankInfo = () => {
    const [ data, setData] = useState({belegnummer: ''} as BankPaymentInfo);

    const loadData = async () => {
        const response: { data: BankPaymentInfo} = await api().get(`user/zahlungen/bankinfo`);

        setData(response.data);
    };

    const { t } = useTranslation();

    React.useEffect(() => {
        loadData();
    }, []);


    return <div className="zahlungen-info-container">
        <h4 className={"sidebar__title"}>{ t("payment_info") }</h4>
        <div className={"sidebar__info"}>{ t("payment_info_description") }</div>

        <div className="sidebar__images">
            <div className="sidebar__image-card">
                <img src="/master_card.png" alt="" />
            </div>
            <div className="sidebar__image-card">
                <img src="/visa.png" alt="" />
            </div>
        </div>

        <br />

        <h4 className={"sidebar__title"}>{ t("payment_bank") }</h4>
        <div className={"sidebar__info"}>{ t("payment_bank_note") }<br/><br/>

            { t("payment_bank_details") }<br/><br/>

            <table>
                <tbody>
                    <tr>
                        <td>{ t("payment_bank_name") }</td>
                        <td style={{fontWeight: 700, paddingLeft: "15px"}}>RAIFFEISENKASSE</td>
                    </tr>
                    <tr>
                        <td>IBAN</td>
                        <td style={{fontWeight: 700, paddingLeft: "15px"}}>AT08 3225 0081 0050 7004</td>
                    </tr>
                    <tr>
                        <td>BIC/SWIFT</td>
                        <td style={{fontWeight: 700, paddingLeft: "15px"}}>RLNWATWWGTD</td>
                    </tr>
                    <tr>
                        <td>{ t("payment_account") }</td>
                        <td style={{fontWeight: 700, paddingLeft: "15px"}}>ÖKV</td>
                    </tr>
                    <tr>
                        <td>{ t("payment_ref") }</td>
                        <td style={{fontWeight: 700, paddingLeft: "15px"}}>{data.belegnummer}</td>
                    </tr>
                </tbody>
            </table>

            <br/>

            { t("payment_note") }
        </div>
    </div>
}

export default BankInfo;
