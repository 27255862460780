import React from "react";

const PrefillRegistrationItem = ({onClick, id, hundename, zuchtbuchnummer}) => {
    return <div className="anmeldung-prefill-list__item" >
        <span className="anmeldung-prefill-list__arrow icon-arrow-left2" onClick={onClick} data-id={id}></span>
        <div className="anmeldung-prefill-list__info" >
            {hundename} <br/>
            ZB-Nr. {zuchtbuchnummer}
        </div>
    </div>
}

export default PrefillRegistrationItem;
