
import LoadingSpinner from "./LoadingSpinner";

const LoadingScreen = (props) => {
    return <div className={"load-screen"}>
        <LoadingSpinner  />
    </div>
}

export default LoadingScreen;
