import React, {useState, Suspense} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import SubFooter from './components/SubFooter';
import RegistrationProcess from './components/RegistrationProcess';
import PasswordChangeForm from "./components/PasswordChangeForm";
import  { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Homepage from "./components/Homepage";
import {NotificationContainer} from 'react-notifications';
import ReactNotification from 'react-notifications-component';

import RegisterUserForm from "./components/RegisterUserForm";
import RegisterUserEditForm from "./components/RegisterUserEditForm";
import MeineAnmeldungListWrapper from "./components/MeineAnmeldungListWrapper";
import ThankYouPage from "./components/ThankYouPage";
import Cookies from 'js-cookie';
import NotFoundRoute from "./components/NotFoundRoute"
import i18n from './i18n';
import LoggedInContext from './contexts/auth';
import { isLoggedIn } from './util/auth';
import { API_URL } from "./constants";
import LoadOverlay from "./components/LoadOverlay";
import LoadingScreen from "./components/LoadingScreen";


function App() {
  const cookieLang = Cookies.get('hundeausstellung_lang', 'de', { domain: API_URL });
  i18n.changeLanguage(cookieLang);

  const [lang, setLang] = useState(cookieLang);
  return (

        <Router>
            <Suspense fallback={<LoadingScreen />}>
                <div className="App">
                    {/* <LoadOverlay/> */}
                    <ReactNotification />
                    <Header/>
                    <div className="content">
                        <NotificationContainer />
                        <Switch>
                            <Route exact path="/" >
                                <Homepage/>
                            </Route>
                            <Route exact path="/anmeldung-list" >
                                <LoggedInContext.Provider value={isLoggedIn()} >
                                    <MeineAnmeldungListWrapper />
                                </LoggedInContext.Provider>
                            </Route>
                            <Route path="/password/reset" >
                                <PasswordChangeForm />
                            </Route>
                            <Route path="/ausstellung/:ausstellung_id" >
                                <LoggedInContext.Provider value={isLoggedIn()} >
                                    <RegistrationProcess />
                                </LoggedInContext.Provider>
                            </Route>
                            <Route exact path="/user/zahlungen" >
                                <LoggedInContext.Provider value={isLoggedIn()} >
                                    <RegistrationProcess />
                                </LoggedInContext.Provider>
                            </Route>
                            <Route exact path="/user/register" >
                                <RegisterUserForm />
                            </Route>
                            <Route exact path="/thank-you" >
                                <ThankYouPage />
                            </Route>
                            <Route path="/user/:user_id" >
                                <LoggedInContext.Provider value={isLoggedIn()} >
                                    <RegisterUserEditForm />
                                </LoggedInContext.Provider>
                            </Route>
                            <Route path="*" component={NotFoundRoute} />
                        </Switch>
                    </div>
                    <Footer />
                    <SubFooter />
                </div>
            </Suspense>
        </Router>

  );
}

export default App;
